import React, { useEffect, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useParams, useHistory } from "react-router-dom";
import { get, post, put } from "src/utils/axios";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Select from "src/components/basic/select";
import { state } from "src/utils/constants";
import { CloseIcon } from "src/assets/icons";
import LegislataButton from "src/components/LegislataButton";
import { setSectionHeader } from "src/slices/global";
import {
  currentOfficeIdSelector,
  fetchTags,
  tagsSelector,
} from "src/slices/office";

const ContactForm = props => {
  const history = useHistory();
  const dispatch = useDispatch();

  // url params
  const { id } = useParams();

  // local state
  const [formVal, setFormVal] = useState({
    name: "",
    address: "",
    city: "",
    state: "",
  });
  const [contactData, setContactData] = useState(null);
  const [emails, setEmails] = useState([]);
  const [phones, setPhones] = useState([]);
  const [associatedTags, setAssociatedTags] = useState([]);

  // from redux
  const currentOfficeId = useSelector(currentOfficeIdSelector);
  const officeTags = useSelector(tagsSelector);

  useEffect(() => {
    if (id) {
      dispatch(setSectionHeader({ pageTitle: "Update Contact Details" }));
    }
  }, []);

  useEffect(() => {
    if (currentOfficeId) {
      dispatch(fetchTags(currentOfficeId));
      if (id) {
        handleLoadContact(id);
      }
    }
  }, [id, currentOfficeId]);

  const handleLoadContact = async contactId => {
    try {
      const result = await get(
        `api/v1/contacts/${contactId}?office_id=${currentOfficeId}`
      );

      const contact = result.data.data;

      setContactData(contact);

      setFormVal({
        name: contact.attributes.name || "",
        address: contact.attributes.street_1 || "",
        city: contact.attributes.city || "",
        state: contact.attributes.state || "",
      });
      const emails = contact.attributes.emails.map(item => item.email);
      setEmails(emails);

      const phones = contact.attributes.phones.map(item => item.phone);
      setPhones(phones);

      setAssociatedTags(
        contact.attributes.tags.map(tag => ({
          value: tag.id,
          label: tag.name,
        }))
      );
    } catch (error) {
      console.log("Load Contact Fail", error);
    }
  };

  const handleChangeFormVal = evt => {
    setFormVal({
      ...formVal,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleChangeKey = (key, value) => {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  };

  const handleChangeEmail = (value, index) => {
    emails[index] = value;
    setEmails([...emails]);
  };

  const handleAddEmail = () => {
    emails.push("");
    setEmails([...emails]);
  };

  const handleRemoveEmail = index => {
    emails.splice(index, 1);
    setEmails([...emails]);
  };

  const handleChangePhone = (value, index) => {
    phones[index] = value;
    setPhones([...phones]);
  };

  const handleAddPhone = (value, index) => {
    phones.push("");
    setPhones([...phones]);
  };

  const handleRemovePhone = index => {
    phones.splice(index, 1);
    setPhones([...phones]);
  };

  const handleSubmit = async evt => {
    evt.preventDefault();
    const emailsParam = [];

    const matchedEmailList = {};

    if (contactData) {
      // existing contact
      emails.forEach(emailItem => {
        const matchedEmail = contactData.attributes.emails.find(
          attEmailItem => attEmailItem.email === emailItem
        );
        if (matchedEmail) {
          emailsParam.push(matchedEmail);
          matchedEmailList[matchedEmail.email] = true;
        } else {
          emailsParam.push({
            email: emailItem,
          });
        }
      });

      contactData.attributes.emails.forEach(emailItem => {
        if (!matchedEmailList[emailItem.email]) {
          emailsParam.push({
            id: emailItem.id,
            _destroy: true,
          });
        }
      });
    } else {
      // new contact
      emails.forEach(emailItem => {
        emailsParam.push({ email: emailItem });
      });
    }

    const phonesParam = [];
    const matchedPhoneList = {};

    if (contactData) {
      phones.forEach(phoneItem => {
        const matchedPhone = contactData.attributes.phones.find(
          attPhoneItem => attPhoneItem.phone === phoneItem
        );
        if (matchedPhone) {
          phonesParam.push(matchedPhone);
          matchedPhoneList[matchedPhone.phone] = true;
        } else {
          phonesParam.push({
            phone: phoneItem,
          });
        }
      });

      contactData.attributes.phones.forEach(phoneItem => {
        if (!matchedPhoneList[phoneItem.phone]) {
          phonesParam.push({
            id: phoneItem.id,
            _destroy: true,
          });
        }
      });
    } else {
      phones.forEach(phoneItem => {
        phonesParam.push({
          phone: phoneItem,
        });
      });
    }

    const param = {
      contact: {
        name: formVal.name,
        street_1: formVal.address,
        city: formVal.city,
        state: formVal.state,
        emails_attributes: emailsParam,
        phones_attributes: phonesParam,
        tag_ids: associatedTags.map(tag => tag.value),
      },
    };

    try {
      if (id) {
        await put(`api/v1/contacts/${id}?office_id=${currentOfficeId}`, param);
      } else {
        await post(`api/v1/contacts?office_id=${currentOfficeId}`, param);
      }
      if (props.onClose) props.onClose();
      window.location.href = "/people";
    } catch (error) {
      console.log("save contact error", error);
    }
  };

  const customStyles = {
    option: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    input: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    multiValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };

  const handleCreateOption = async value => {
    try {
      const res = await post(`/api/v1/tags?office_id=${currentOfficeId}`, {
        name: value,
      });
      if (res.data.data) {
        dispatch(fetchTags(currentOfficeId));
        const tag = res.data.data;
        setAssociatedTags(
          associatedTags.concat({ value: tag.id, label: tag.attributes.name })
        );
      }
    } catch (error) {
      toast("Something broke, please retry your request…");
    }
  };

  const handleSelectOption = async values => {
    setAssociatedTags(values);
  };

  const handleChangeContacts = (values, actionMeta) => {
    switch (actionMeta.action) {
      case "create-option":
        handleCreateOption(actionMeta.option.value);
        break;
      case "select-option":
        handleSelectOption(values);
        break;
      case "remove-value":
        handleSelectOption(values);
        break;
      default:
        break;
    }
  };

  const searchOptions = async term => {
    // start search after a minimum of 3 typed characters
    if (term.length < 3) return [];

    try {
      if (currentOfficeId) {
        let response;
        response = await get(
          `api/v1/tags?office_id=${currentOfficeId}&query=${term}&per_page=100`
        );

        const results = response.data.data;
        let options = [];
        if (results.length > 0) {
          options = results.map(option => {
            return {
              value: option.id,
              label: option.attributes.name,
            };
          });
          return options;
        } else {
          return [];
        }
      }
    } catch (e) {
      return [];
    }
  };

  const lookupOptions = inputValue => {
    return new Promise(resolve => {
      resolve(searchOptions(inputValue));
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="space-y-4">
          <div className="flex flex-col">
            <label className="label col-span-2">Name</label>
            <input
              className="border border-gray-300 rounded-md p-1.5"
              value={formVal.name}
              name="name"
              onChange={handleChangeFormVal}
              required
            ></input>
          </div>
          <div className="flex flex-col">
            <label className="label col-span-2">Email</label>
            <div>
              {emails.map((email, index) => (
                <div key={`key-email-item-${index}`} className="flex my-1">
                  <input
                    className="border border-gray-300 rounded-md p-1.5 w-full mr-2"
                    value={email}
                    onChange={evt => handleChangeEmail(evt.target.value, index)}
                  ></input>
                  {index !== 0 && (
                    <button
                      type="button"
                      onClick={() => handleRemoveEmail(index)}
                    >
                      <CloseIcon />
                    </button>
                  )}
                </div>
              ))}
            </div>
            <a
              onClick={handleAddEmail}
              className="text-sm text-blue-600 cursor-pointer"
            >
              + Add Email
            </a>
          </div>
          <div className="flex flex-col">
            <label className="label col-span-2">Phone</label>
            <div>
              {phones.map((phone, index) => (
                <div key={`key-phone-item-${index}`} className="flex my-1">
                  <input
                    className="border border-gray-300 rounded-md p-1.5 w-full mr-2"
                    value={phone}
                    onChange={evt => handleChangePhone(evt.target.value, index)}
                  ></input>
                  <button
                    type="button"
                    onClick={() => handleRemovePhone(index)}
                  >
                    <CloseIcon />
                  </button>
                </div>
              ))}
            </div>
            <a
              onClick={handleAddPhone}
              className="text-sm text-blue-600 cursor-pointer"
            >
              + Add Phone
            </a>
          </div>
          <div className="flex flex-col">
            <label className="label col-span-2">Address</label>
            <input
              className="border border-gray-300 rounded-md p-1.5"
              value={formVal.address}
              name="address"
              onChange={handleChangeFormVal}
            ></input>
          </div>
          <div className="flex items-center">
            <div className="flex flex-col flex-1 mr-3">
              <label className="label col-span-2">City</label>
              <input
                className="border border-gray-300 rounded-md p-1.5"
                value={formVal.city}
                name="city"
                onChange={handleChangeFormVal}
              ></input>
            </div>
            <div className="flex-1">
              <Select
                label="State"
                labelStyle="label col-span-2"
                placeholder="Please select"
                data={state}
                initValue={formVal.state}
                onSelected={value => handleChangeKey("state", value.value)}
              />
            </div>
          </div>
          <div className="flex flex-col">
            <label className="label col-span-2">Tags</label>
            <AsyncCreatableSelect
              isMulti
              value={associatedTags}
              styles={customStyles}
              name="tags"
              loadOptions={lookupOptions}
              onChange={handleChangeContacts}
              placeholder="Add or search a tag"
            />
          </div>
          <div className="flex justify-end">
            <LegislataButton
              type="primary"
              value="Save"
              onClick={handleSubmit}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
