import React, { useEffect } from "react";
import { useQuill } from "react-quilljs";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { renderToString } from "react-dom/server";

import {
  BoldIcon,
  ItalicIcon,
  UnderlineIcon,
  LinkIcon,
  ImageIcon,
  ListOrderedIcon,
  ListBulletIcon,
} from "../assets/icons";

const icons = Quill.import("ui/icons");

const style = {
  borderLeftColor: "transparent",
  borderRightColor: "transparent",
  borderBottomColor: "transparent",
  borderTopColor: "transparent", // rgba(229, 231, 235, 1)
  height: "16rem",
};

const toolBar = {
  borderLeftColor: "transparent",
  borderRightColor: "transparent",
  borderBottomColor: "rgba(229, 231, 235, 1",
  borderTopColor: "rgba(229, 231, 235, 1",
};

export default props => {
  const {
    isClearedEditor,
    setIsClearedEditor,
    setEmailBody,
    content = null,
    body = null,
  } = props;
  const placeholder = "Type...";
  const { quill, quillRef } = useQuill({
    modules: {
      toolbar: "#toolbar",
    },
    formats: [
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "link",
      "image",
      "list",
      "clean",
    ],
    placeholder,
  });
  useEffect(() => {
    if (quill && content) {
      quill.clipboard.dangerouslyPasteHTML(content);
    }
  }, [quill, content]);

  useEffect(() => {
    if (quill && body) {
      quill.clipboard.dangerouslyPasteHTML(body);
    }
  }, [quill]);

  useEffect(() => {
    if (isClearedEditor) {
      quill.setText("");
      setIsClearedEditor(false);
    }
  }, [isClearedEditor]);

  useEffect(() => {
    if (quill) {
      quill.on("text-change", () => {
        setEmailBody(quill.root.innerHTML);
      });
    }
  }, [quill]);

  icons["bold"] = renderToString(<BoldIcon />);
  icons["italic"] = renderToString(<ItalicIcon />);
  icons["underline"] = renderToString(<UnderlineIcon />);
  icons["link"] = renderToString(<LinkIcon />);
  icons["image"] = renderToString(<ImageIcon />);
  icons.list.ordered = renderToString(<ListOrderedIcon />);
  icons.list.bullet = renderToString(<ListBulletIcon />);

  return (
    <>
      <div className="h-48" style={style} ref={quillRef} />
      <div style={toolBar} id="toolbar">
        <button className="ql-bold" />
        <button className="ql-italic" />
        <button className="ql-underline mr-3" />
        <button className="ql-link" />
        <button className="ql-image mr-2" />
        <button className="ql-list" value="ordered" />
        <button className="ql-list" value="bullet" />
      </div>
    </>
  );
};
