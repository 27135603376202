import React from "react";

import { Redirect } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import InvitedOfficeLogin from "src/views/Office/InvitedOfficeLogin";

const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const pathName = window.location.pathname;
  const targetPathName = pathName.split("/")[1];

  if (!isAuthenticated) {
    if (targetPathName === "offices") return <InvitedOfficeLogin />;
    else return <Redirect to="/login" />;
  }

  return <>{children}</>;
};

export default AuthGuard;
