import React from "react";

const LegislataButton = ({
  children,
  value,
  textSize,
  styles,
  iconPositionFront = true,
  onClick,
  onButtonClick,
  type = "primary",
  customStyle = "",
  disabled = false,
}) => {
  const iconPosition = iconPositionFront;
  const fontSize = textSize || "text-sm";
  if (disabled) type = "plain";
  let buttonStyle;
  if (type === "primary") {
    buttonStyle =
      "bg-blue-600 border border-transparent hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 text-white";
  } else if (type === "secondary") {
    buttonStyle =
      "bg-indigo-100 border border-transparent hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-indigo-700";
  } else if (type === "plain") {
    buttonStyle =
      "bg-white border border-gray-300 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 text-gray-700";
  } else if (type === "none") {
    buttonStyle = "bg-white text-gray-700 hover:text-black";
  } else if (type === "danger") {
    buttonStyle = "bg-red-600 text-white hover:bg-red-700 border-0";
  } else if (type === "outline") {
    buttonStyle =
      "bg-transparent hover:text-white border hover:border-transparent";
  } else if (type === "outline-disable") {
    buttonStyle = "bg-transparent border";
  }

  return (
    <button
      disabled={disabled}
      className={`${customStyle} inline-flex items-center px-2 py-1.5 font-medium rounded-sm space-x-1 ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      } text-sm ${buttonStyle}`}
      onClick={onClick}
    >
      {iconPosition && children && <div className="text-sm">{children}</div>}
      {!!value && <div className="text-center">{value}</div>}
      {!iconPosition && children && <div className="text-sm">{children}</div>}
    </button>
  );
};

export default LegislataButton;
