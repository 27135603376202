import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";

const PublicPopup = props => {
  const { isOpen, onClose } = props;

  const history = useHistory();

  return (
    <Modal open={isOpen} onClose={onClose} closeOnOverlayClick={true} center>
      <div
        className="relative bg-white rounded-lg dark:bg-gray-700"
        style={{ minWidth: "400px" }}
      >
        <div className="px-2 pt-6 lg:px-3">
          <h3 className="mb-4 text-xl font-medium text-gray-900 dark:text-white text-center">
            Explore more
          </h3>
          <div className="space-y-6">
            <div>
              <p className="text-center py-1">
                This functionality is only available to logged in users.
              </p>
              <p className="text-center py-1">
                To log in or create a free account visit app.legislata.com.
              </p>
            </div>
            <div className="flex justify-center">
              <button
                type="button"
                className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800"
                onClick={() => history.push("/login")}
              >
                Login
              </button>
              <button
                type="button"
                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                onClick={() => history.push("/register")}
              >
                Register
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PublicPopup;
