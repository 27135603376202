import React, { useState } from "react";
import { post } from "src/utils/axios";
import { useSelector } from "react-redux";
import { FORM_TYPE_NONE } from "src/assets/consts/message";
import Select from "src/components/basic/select";
import { state } from "src/utils/constants";
import toast from "react-hot-toast";

const ContactForm = props => {
  const office = useSelector(state => state.office);
  const [formVal, setFormVal] = useState({
    name: "",
    email: "",
    phone: "",
    street_1: "",
    city: "",
    state: "",
  });
  const handleSubmit = async evt => {
    evt.preventDefault();
    const { handleContactList, handleSelectContact, handleFormType } = props;
    const officeId = office.currentOffice.id;
    try {
      const result = await post(`api/v1/contacts?office_id=${officeId}`, {
        ...formVal,
        emails_attributes: [
          {
            email: formVal.email,
          },
        ],
        phones_attributes: [
          {
            phone: formVal.phone,
          },
        ],
      });
      handleContactList({
        type: "new",
        data: result?.data?.data,
      });
      handleSelectContact(result?.data?.data);
      handleFormType(FORM_TYPE_NONE);
    } catch (error) {
      console.log("error --", error);
      toast("Something broke, please retry your request…");
    }
  };

  const handleChange = evt => {
    setFormVal({
      ...formVal,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleChangeKey = (key, value) => {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  };

  return (
    <div className="border my-3 rounded-sm p-3">
      <form onSubmit={handleSubmit} className="text-sm mt-2">
        <div className="my-1">
          <label className="label col-span-2">Name*</label>
          <input
            className="form-input w-full"
            name="name"
            required
            value={formVal.name}
            onChange={handleChange}
          />
        </div>
        <div className="my-1">
          <label className="label col-span-2">Email*</label>
          <input
            className="form-input w-full"
            name="email"
            required
            value={formVal.email}
            onChange={handleChange}
          />
        </div>
        <div className="my-1">
          <label className="label col-span-2">Phone*</label>
          <input
            className="form-input w-full"
            name="phone"
            required
            value={formVal.phone}
            onChange={handleChange}
          />
        </div>
        <div className="my-1">
          <label className="label col-span-2">Street</label>
          <input
            className="form-input w-full"
            name="street_1"
            value={formVal.street_1}
            onChange={handleChange}
          />
        </div>
        <div className="my-1">
          <label className="label col-span-2">City</label>
          <input
            className="form-input w-full"
            name="city"
            value={formVal.city}
            onChange={handleChange}
          />
        </div>
        <div className="my-1">
          <Select
            label="State"
            labelStyle="label col-span-2"
            placeholder="Please select"
            data={state}
            onSelected={value => handleChangeKey("state", value.value)}
          />
        </div>
        <div className="justify-end flex mt-2">
          <button className="border bg-green-500 text-white	p-2" type="submit">
            Create New Contact
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
