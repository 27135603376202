import React, { useState, useEffect } from "react";

import "src/assets/elements.css";
import LegislataButton from "src/components/LegislataButton";
import { get, post, put } from "src/utils/axios";
import MessageContactDetail from "./MessageContactDetail";
import MessageContactForm from "./MessageContactForm";
import { useSelector } from "react-redux";
import {
  ContactBills,
  ContactBillPosition,
  ContactTopics,
} from "../../InboxDetail";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import {
  MESSAGE_TYPE_NEW,
  MESSAGE_TYPE_RECEIVED,
  MESSAGE_TYPE_SENT,
  MESSAGE_TYPE_EDIT,
} from "src/assets/consts/message";
import toast from "react-hot-toast";

const NewMessage = props => {
  const office = useSelector(state => state.office);
  const [isOpen, setIsOpen] = useState(true);
  const [formVal, setFormVal] = useState({
    type: MESSAGE_TYPE_RECEIVED,
    subject: "",
    body: "",
  });
  const [contactList, setContactList] = useState([]);
  const [selectContact, setSelectContact] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [bills, setBills] = useState(null);
  const [billMessage, setBillMessage] = useState(null);
  const [billsOffice, setBillsOffice] = useState(null);
  const [topics, setTopics] = useState(null);
  const [topicsOffice, setTopicsOffice] = useState(null);
  const [issues, setIssues] = useState(null);
  const [issuesOffice, setIssuesOffice] = useState(null);
  const [position, setPosition] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [tagIds, setTagIds] = useState([]);

  let initTags = [];
  if (props.type === "MESSAGE_TYPE_EDIT") initTags = props.data.attributes.tags;
  const [tags, setTags] = useState(initTags);

  const [formError, setFormError] = useState({
    contact: "",
    subject: "",
    body: "",
  });

  useEffect(() => {
    handleLoadContacts();
    // handleLoadTags();
    handleLoadBills();
    handleLoadTopics();
    handleLoadIssues();
  }, [office]);

  useEffect(() => {
    const { type = MESSAGE_TYPE_NEW, data } = props;
    if (type === MESSAGE_TYPE_EDIT) {
      setFormVal({
        type: data.attributes.msg_type,
        subject: data.attributes.subject,
        body: data.attributes.body,
      });

      const contact = {
        id: data.attributes.contact.id,
        type: "contacts",
        attributes: data.attributes.contact,
      };

      setSelectContact(contact);
      handleLoadMessageBills();
      handleLoadMessageTopics();
      handleLoadMessageIssues();
      // handleLoadMessageTags();
    }
  }, []);

  const handleLoadMessageBills = async () => {
    const { data } = props;
    const officeId = office.currentOffice.id;
    try {
      const result = await get(
        `api/v1/messages/${data.id}/bills?office_id=${officeId}`
      );
      if (result?.data?.data && result.data.data.length > 0) {
        const bill = {
          attributes: result.data.data[0].attributes?.bill,
          id: result.data.data[0].attributes?.bill.id,
          type: "bills",
        };
        setBills([bill]);
        setBillMessage(result.data.data);

        if (result.data.data[0].attributes?.position) {
          setPosition({
            value: result.data.data[0].attributes?.position,
            label: "",
          });
        }
      }
    } catch (error) {
      toast("Something broke, please retry your request…");
      console.log("message bill loading error", error);
    }
  };

  const handleLoadMessageTopics = async () => {
    const { data } = props;
    const officeId = office.currentOffice.id;
    try {
      const result = await get(
        `api/v1/messages/${data.id}/topics?office_id=${officeId}`
      );
      setTopics(result?.data?.data);
    } catch (error) {
      console.log("message topic loading error", error);
      toast("Something broke, please retry your request…");
    }
  };

  const handleLoadMessageIssues = async () => {
    const { data } = props;
    const officeId = office.currentOffice.id;
    try {
      const result = await get(
        `api/v1/messages/${data.id}/issues?office_id=${officeId}`
      );
      setIssues(result?.data?.data);
    } catch (error) {
      toast("Something broke, please retry your request…");
      console.log("message issues loading error", error);
    }
  };

  // const handleLoadMessageTags = async () => {
  //   const { data } = props;
  //   const officeId = office.currentOffice.id;
  //   try {
  //     const result = await get(
  //       `api/v1/messages/${data.id}/tags?office_id=${officeId}`
  //     );
  //     console.log(result.data.data)
  //     setTags(result?.data?.data);
  //   } catch (error) {
  //     toast("Something broke, please retry your request…");
  //     console.log("message tags loading error", error);
  //   }
  // };

  const handleLoadBills = async () => {
    try {
      let result = await get(
        `api/v1/bills?office_id=${office.currentOffice.id}`
      );
      setBillsOffice(result?.data?.data);
    } catch (error) {
      toast("Something broke, please retry your request…");
      console.log("bills load error", error);
    }
  };

  const handleLoadTopics = async () => {
    try {
      let result = await get(
        `api/v1/topics?office_id=${office.currentOffice.id}`
      );
      setTopicsOffice(result?.data?.data);
    } catch (error) {
      toast("Something broke, please retry your request…");
      console.log("topics load error", error);
    }
  };

  const handleLoadIssues = async () => {
    try {
      let result = await get(
        `api/v1/issues?office_id=${office.currentOffice.id}`
      );
      setIssuesOffice(result?.data?.data);
    } catch (error) {
      toast("Something broke, please retry your request…");
      console.log("issue load error", error);
    }
  };

  const handleLoadContacts = async () => {
    try {
      const officeId = office.currentOffice.id;
      const result = await get(`api/v1/contacts?office_id=${officeId}`);
      setContactList(result?.data?.data);
    } catch (error) {
      toast("Something broke, please retry your request…");
      console.log("error", error);
    }
  };

  // const handleLoadTags = async () => {
  //   try {
  //     get(`api/v1/tags?office_id=${office.currentOffice.id}`).then(res => {
  //       setTagList(res.data.data);
  //     });
  //   } catch (error) {
  //     toast("Something broke, please retry your request…");
  //     console.log("handle load tag", error);
  //   }
  // };

  const closeModal = () => {
    const { onClose, type } = props;
    if (type === MESSAGE_TYPE_NEW) {
      setConfirmModal(true);
    } else {
      onClose();
    }
  };

  const handleConfirmYes = () => {
    const { onClose } = props;
    setConfirmModal(false);
    setIsOpen(false);
    onClose();
  };

  const handleConfirmNo = () => {
    setConfirmModal(false);
  };

  const handleValidateForm = () => {
    const error = {};
    let errorNotify = false;
    if (!selectContact) {
      error["contact"] = "You need to select or create a contact";
      errorNotify = true;
    }

    if (formVal.subject === "") {
      error["subject"] = "You need to input any subject text";
      errorNotify = true;
    }

    if (formVal.body === "") {
      error["body"] = "You need to input any body text";
      errorNotify = true;
    }
    setFormError(error);
    return errorNotify;
  };

  const submitForm = async () => {
    const officeId = office.currentOffice.id;
    const {
      handleMessageUpdate,
      onClose,
      type = MESSAGE_TYPE_NEW,
      data,
    } = props;
    try {
      const formValidation = handleValidateForm();
      if (formValidation) {
        return;
      }
      if (type === MESSAGE_TYPE_NEW) {
        const param = {
          message: {
            msg_type: formVal.type,
            subject: formVal.subject,
            body: formVal.body,
            message_bills_attributes:
              bills && bills.length > 0
                ? [{ bill_id: bills[0].id, position: position.value }]
                : [],
            topic_ids: topics ? topics.map(item => item.id) : [],
            issue_ids: issues ? issues.map(item => item.id) : [],
            tag_ids: tags ? tags.map(item => item.id) : [],
          },
        };
        const response = await post(
          `api/v1/contacts/${selectContact.id}/messages?office_id=${officeId}`,
          param
        );
        handleMessageUpdate({
          type: "new",
          data: response.data.data,
        });
      } else if (props.type === MESSAGE_TYPE_EDIT) {
        let messageBillAttributes = [];
        if (billMessage) {
          if (bills && bills.length > 0) {
            messageBillAttributes = [
              {
                id: billMessage[0].id,
                bill_id: bills[0].id,
                position: position.value,
              },
            ];
          } else {
            messageBillAttributes = [{ id: billMessage[0].id, _destroy: true }];
          }
        } else {
          messageBillAttributes =
            bills && bills.length > 0
              ? [{ bill_id: bills[0].id, position: position.value }]
              : [];
        }
        const param = {
          message: {
            msg_type: formVal.type,
            subject: formVal.subject,
            body: formVal.body,
            message_bills_attributes: messageBillAttributes,
            topic_ids: topics.map(item => item.id),
            issue_ids: issues.map(item => item.id),
            tag_ids: tags.map(item => item.id),
          },
        };
        const response = await put(
          `api/v1/messages/${data.id}?office_id=${officeId}`,
          param
        );
        handleMessageUpdate({
          type: "update",
          data: response.data.data,
        });
      }
      onClose();
    } catch (error) {
      console.log("new message error", error);
      toast("Something broke, please retry your request…");
    }
  };

  const handleChange = (key, value) => {
    setFormVal({
      ...formVal,
      [key]: value,
    });
  };

  const handleContactList = param => {
    const { type, data } = param;
    if (type === "new") {
      contactList.push(data);
      setContactList([...contactList]);
    } else if (type === "update") {
      const updateIndex = contactList.findIndex(item => item.id === data.id);
      if (updateIndex >= 0) {
        contactList.splice(updateIndex, 1, data);
      }
      setContactList([...contactList]);
    }
  };

  const handleTagList = param => {
    const { type, data } = param;
    if (type === "new") {
      tagList.push(data);
      setTagList([...tagList]);
    }
  };

  const handleUpdateValue = (newValue, type) => {
    if (type === "topic") {
      const newValueList = topicsOffice.filter(item => {
        const itemIndex = newValue.findIndex(
          itemValue => itemValue.value === item.id
        );
        if (itemIndex >= 0) {
          return true;
        } else {
          return false;
        }
      });
      setTopics(newValueList);
    } else if (type === "issue") {
      const newValueList = issuesOffice.filter(item => {
        const itemIndex = newValue.findIndex(
          itemValue => itemValue.value === item.id
        );
        if (itemIndex >= 0) {
          return true;
        } else {
          return false;
        }
      });
      setIssues(newValueList);
    } else if (type === "bill") {
      const newValueUpdate = billsOffice.find(
        item => item.id === newValue?.value
      );
      if (newValueUpdate) {
        setBills([newValueUpdate]);
      } else {
        setBills([]);
      }
    } else if (type === "tag") {
      setTags(newValue);
    }
  };

  const handleAddOption = (newOption, type) => {
    if (type === "topic") {
      topicsOffice.push(newOption);
      setTopicsOffice([...topicsOffice]);
    } else if (type === "issue") {
      issuesOffice.push(newOption);
      setIssuesOffice([...issuesOffice]);
    }
  };

  return (
    <Modal
      classNames={{ modal: "rounded-sm" }}
      open={isOpen}
      onClose={closeModal}
      closeOnOverlayClick={true}
    >
      <div className="flex flex-col">
        <div className="border-b-1 p-4">
          <div className="text-xl">
            {props.type === MESSAGE_TYPE_NEW ? "New" : "Edit"} Message
          </div>
        </div>
        <div className="p-4">
          <div className="space-y-4" style={{ minWidth: "400px" }}>
            <MessageContactForm
              type={props.type}
              contactList={contactList}
              selectContact={selectContact}
              formError={formError}
              handleContactList={handleContactList}
              handleSelectContact={contact => {
                setSelectContact(contact);
                setFormError({
                  ...formError,
                  contact: "",
                });
              }}
            />
            {selectContact && (
              <div>
                <label className="label col-span-2">
                  Was this message received or sent?
                </label>
                <div className="flex items-center">
                  <div className="mr-3">
                    <label className="col-span-2">Received: </label>
                    <input
                      type="radio"
                      value={MESSAGE_TYPE_RECEIVED}
                      checked={formVal.type === MESSAGE_TYPE_RECEIVED}
                      onChange={evt => handleChange("type", evt.target.value)}
                    />
                  </div>
                  <div>
                    <label className="col-span-2">Sent: </label>
                    <input
                      type="radio"
                      value={MESSAGE_TYPE_SENT}
                      checked={formVal.type === MESSAGE_TYPE_SENT}
                      onChange={evt => handleChange("type", evt.target.value)}
                    />
                  </div>
                </div>
              </div>
            )}
            {selectContact && (
              <div className="flex flex-col space-y-1">
                <label className="label col-span-2">Message Subject</label>
                <input
                  className="form-input"
                  type="text"
                  value={formVal.subject}
                  onChange={evt => {
                    setFormError({
                      ...formError,
                      subject: "",
                    });
                    handleChange("subject", evt.target.value);
                  }}
                  placeholder="Enter a subject for the message"
                />
                <p className="text-xs text-red-500">{formError.subject}</p>
              </div>
            )}

            {selectContact && (
              <div className="flex flex-col space-y-1">
                <label className="label col-span-2">Message Details</label>
                <textarea
                  rows={4}
                  className="form-input"
                  value={formVal.body}
                  onChange={evt => {
                    setFormError({
                      ...formError,
                      body: "",
                    });
                    handleChange("body", evt.target.value);
                  }}
                  placeholder="Enter the details of the message"
                />
                <p className="text-xs text-red-500">{formError.body}</p>
              </div>
            )}

            {selectContact && (
              <MessageContactDetail
                handleUpdateValue={newValue =>
                  handleUpdateValue(newValue, "tag")
                }
                valueList={tags}
              />
            )}

            {selectContact && (
              <div className="">
                <div>
                  <div className="flex align-items-center">
                    <div className="w-1/2 mr-1">
                      <div className="text-sm p-1">Bills</div>
                      <div className="w-full">
                        <ContactBills
                          type="bill"
                          source="no-api"
                          optionList={billsOffice || []}
                          valueList={bills || []}
                          handleUpdateValue={newValue =>
                            handleUpdateValue(newValue, "bill")
                          }
                        />
                      </div>
                    </div>
                    <div className="w-1/2 ml-1">
                      <div className="text-sm p-1">Position</div>
                      <div className="w-full">
                        <ContactBillPosition
                          billId={"billid"}
                          valueList={position?.value}
                          source="no-api"
                          handleUpdateValue={newValue => setPosition(newValue)}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="text-sm p-1">Subtopics</div>
                  <div className="w-full">
                    <ContactTopics
                      type="issue"
                      optionList={issuesOffice}
                      source="no-api"
                      valueList={issues}
                      handleUpdateValue={newValue =>
                        handleUpdateValue(newValue, "issue")
                      }
                      handleAddOption={newOption =>
                        handleAddOption(newOption, "issue")
                      }
                    />
                  </div>
                  <div className="text-sm p-1">Topic</div>
                  <div className="w-full">
                    <ContactTopics
                      type="topic"
                      source="no-api"
                      optionList={topicsOffice}
                      valueList={topics}
                      handleUpdateValue={newValue =>
                        handleUpdateValue(newValue, "topic")
                      }
                      handleAddOption={newOption =>
                        handleAddOption(newOption, "topic")
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <div className="flex flex-row justify-end items-center space-x-8">
              <button onClick={() => closeModal()}>Cancel</button>
              <LegislataButton
                value="Save"
                styles="primary-button"
                iconPositionFront={false}
                onClick={submitForm}
              ></LegislataButton>
            </div>
          </div>
        </div>
      </div>

      <Modal
        open={confirmModal}
        onClose={handleConfirmNo}
        center={true}
        closeOnOverlayClick={false}
      >
        <h2>Confirm to Close</h2>
        <p>
          {props.type === MESSAGE_TYPE_NEW
            ? "Are you sure you want to discard this new message?"
            : "Are you sure you want to discard unsaved changes?"}
        </p>
        <div className="flex justify-end mt-2">
          <button
            className="bg-transparent text-black text-sm py-1 px-3 mr-2"
            onClick={handleConfirmNo}
          >
            No
          </button>
          <LegislataButton
            value="Yes"
            styles="primary-button"
            iconPositionFront={false}
            onClick={handleConfirmYes}
          ></LegislataButton>
        </div>
      </Modal>
    </Modal>
  );
};

export default NewMessage;
