import { combineReducers } from "@reduxjs/toolkit";
import { reducer as global } from "src/slices/global";
import { reducer as task } from "src/slices/task";
import { reducer as profile } from "src/slices/profile";
import { reducer as app } from "src/slices/app";
import { reducer as office } from "src/slices/office";
import { reducer as workflow } from "src/slices/workflow";

const rootReducer = combineReducers({
  global: global,
  profile: profile,
  app: app,
  office: office,
  workflow: workflow,
  task: task,
});

export default rootReducer;
