import React, { useState } from "react";
import { useSelector } from "react-redux";
import { post, put } from "src/utils/axios";
import Editor from "src/components/Editor";
import RichTextEditor from "src/components/RichTextEditor";

const MODAL_TYPE_NEW = "MODAL_TYPE_NEW";
const MODAL_TYPE_EDIT = "MODAL_TYPE_EDIT";

const TemplateModal = props => {
  const { data } = props;
  const office = useSelector(state => state.office);

  const [templateName, setTemplateName] = useState(
    data?.attributes?.name || ""
  );

  const [templateSubject, setTemplateSubject] = useState(
    data?.attributes?.subject || ""
  );

  const [templateBody, setTemplateBody] = useState(
    data?.attributes?.body || ""
  );

  const [isClearedEditor, setIsClearedEditor] = useState(false);

  const handleSubmit = async evt => {
    evt.preventDefault();

    const { handleAddTemplate, handleUpdateTemplate, handleCloseModal } = props;

    const officeId = office.currentOffice.id;

    if (props.type === MODAL_TYPE_NEW) {
      try {
        const result = await post(
          `api/v1/email_templates?office_id=${officeId}`,
          {
            name: templateName,
            subject: templateSubject,
            body: templateBody,
          }
        );
        handleAddTemplate(result?.data?.data);
        handleCloseModal();
      } catch (error) {
        console.log("error --- ", error);
      }
    } else {
      try {
        const result = await put(
          `api/v1/email_templates/${data.id}?office_id=${officeId}`,
          {
            name: templateName,
            subject: templateSubject,
            body: templateBody,
          }
        );
        handleUpdateTemplate(result?.data?.data);
        handleCloseModal();
      } catch (error) {
        console.log("error --- ", error);
      }
    }
  };

  const handleCancel = () => {
    const { handleCloseModal } = props;
    handleCloseModal();
  };
  return (
    <div style={{ minWidth: "400px" }}>
      <div className="flex flex-col">
        <div className="border-b-1 p-4">
          <div className="text-xl">
            {props.type === MODAL_TYPE_NEW ? "New Template" : "Edit Template"}
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col space-y-1 p-2 mt-2">
          <label className="label">Template Name</label>
          <input
            name="name"
            className="form-input text-field"
            value={templateName}
            required
            onChange={evt => setTemplateName(evt.target.value)}
          ></input>
          {/* <p className="text-red-500 text-sm">{subjectErrorMessage}</p> */}
        </div>
        <div className="flex flex-col space-y-1 p-2">
          <label className="label">Subject</label>
          <input
            name="subject"
            className="form-input text-field"
            value={templateSubject}
            required
            onChange={evt => setTemplateSubject(evt.target.value)}
          ></input>
          {/* <p className="text-red-500 text-sm">{subjectErrorMessage}</p> */}
        </div>
        <div className="flex flex-col space-y-1 p-2">
          <label className="label">Body</label>
          <RichTextEditor
            body={templateBody}
            isClearedEditor={isClearedEditor}
            setIsClearedEditor={setIsClearedEditor}
            setEmailBody={body => setTemplateBody(body)}
          />
        </div>
        <div className="flex justify-end mt-6">
          <button
            className="py-2 px-4 bg-gray-400 text-white rounded-sm ml-2"
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="py-2 px-4 bg-blue-400 text-white rounded-sm ml-2"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
};

export default TemplateModal;
