import React from "react";

export const PlusIconOutline = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M12 4v16m8-8H4"
      />
    </svg>
  );
};

export const BackIcon = () => {
  return (
    <svg
      width="8"
      height="8"
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99998 4C7.99998 4.13261 7.9473 4.25978 7.85353 4.35355C7.75976 4.44732 7.63259 4.5 7.49998 4.5H1.70698L3.85398 6.646C3.90047 6.69249 3.93734 6.74768 3.9625 6.80841C3.98766 6.86915 4.00061 6.93425 4.00061 7C4.00061 7.06574 3.98766 7.13084 3.9625 7.19158C3.93734 7.25232 3.90047 7.30751 3.85398 7.354C3.80749 7.40049 3.7523 7.43736 3.69156 7.46252C3.63082 7.48768 3.56572 7.50063 3.49998 7.50063C3.43423 7.50063 3.36913 7.48768 3.30839 7.46252C3.24766 7.43736 3.19247 7.40049 3.14598 7.354L0.145978 4.354C0.099415 4.30755 0.062472 4.25238 0.0372655 4.19163C0.0120591 4.13089 -0.000915527 4.06577 -0.000915527 4C-0.000915527 3.93423 0.0120591 3.86911 0.0372655 3.80836C0.062472 3.74762 0.099415 3.69244 0.145978 3.646L3.14598 0.645998C3.23986 0.552112 3.3672 0.499367 3.49998 0.499367C3.63275 0.499367 3.76009 0.552112 3.85398 0.645998C3.94787 0.739885 4.00061 0.867223 4.00061 0.999999C4.00061 1.13277 3.94787 1.26011 3.85398 1.354L1.70698 3.5H7.49998C7.63259 3.5 7.75976 3.55268 7.85353 3.64645C7.9473 3.74021 7.99998 3.86739 7.99998 4Z"
        fill="#384555"
      />
    </svg>
  );
};

export const FlagIcon = () => {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.08826 11.4725H0.304504V0.5H9.70952L7.43664 4.02688L9.70952 7.55376H1.08826V11.4725Z"
        fill="#384555"
      />
      <path
        d="M8.26997 6.65597H1.1789L1.08557 6.65596V1.08885H8.45655L6.46607 3.98126L8.26997 6.65597Z"
        fill="#C4C4C4"
      />
    </svg>
  );
};

export const WorkflowIcon = () => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.33795 0.5V4.5H6.83795V5.5H1.83795V7.5H0.337952V11.5H4.33795V7.5H2.83795V6.5H6.83795V7.5H5.33795V11.5H9.33795V7.5H7.83795V6.5H11.838V7.5H10.338V11.5H14.338V7.5H12.838V5.5H7.83795V4.5H9.33795V0.5H5.33795ZM6.33795 1.5H8.33795V3.5H6.33795V1.5ZM1.33795 8.5H3.33795V10.5H1.33795V8.5ZM6.33795 8.5H8.33795V10.5H6.33795V8.5ZM11.338 8.5H13.338V10.5H11.338V8.5Z"
        fill="#384555"
      />
    </svg>
  );
};

export const CloseIcon = ({ width, height, color = "#7D8085" }) => {
  return (
    <svg
      width={width || 8}
      height={height || 8}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.645978 0.645978C0.692424 0.599415 0.747599 0.562472 0.808344 0.537265C0.86909 0.512059 0.934211 0.499084 0.999978 0.499084C1.06575 0.499084 1.13087 0.512059 1.19161 0.537265C1.25236 0.562472 1.30753 0.599415 1.35398 0.645978L3.99998 3.29298L6.64598 0.645978C6.69247 0.59949 6.74765 0.562614 6.80839 0.537455C6.86913 0.512296 6.93423 0.499346 6.99998 0.499346C7.06572 0.499346 7.13082 0.512296 7.19156 0.537455C7.2523 0.562614 7.30749 0.59949 7.35398 0.645978C7.40047 0.692466 7.43734 0.747655 7.4625 0.808394C7.48766 0.869134 7.50061 0.934234 7.50061 0.999978C7.50061 1.06572 7.48766 1.13082 7.4625 1.19156C7.43734 1.2523 7.40047 1.30749 7.35398 1.35398L4.70698 3.99998L7.35398 6.64598C7.40047 6.69247 7.43734 6.74765 7.4625 6.80839C7.48766 6.86913 7.50061 6.93423 7.50061 6.99998C7.50061 7.06572 7.48766 7.13082 7.4625 7.19156C7.43734 7.2523 7.40047 7.30749 7.35398 7.35398C7.30749 7.40047 7.2523 7.43734 7.19156 7.4625C7.13082 7.48766 7.06572 7.50061 6.99998 7.50061C6.93423 7.50061 6.86913 7.48766 6.80839 7.4625C6.74765 7.43734 6.69247 7.40047 6.64598 7.35398L3.99998 4.70698L1.35398 7.35398C1.30749 7.40047 1.2523 7.43734 1.19156 7.4625C1.13082 7.48766 1.06572 7.50061 0.999978 7.50061C0.934234 7.50061 0.869134 7.48766 0.808394 7.4625C0.747655 7.43734 0.692466 7.40047 0.645978 7.35398C0.59949 7.30749 0.562614 7.2523 0.537455 7.19156C0.512296 7.13082 0.499346 7.06572 0.499346 6.99998C0.499346 6.93423 0.512296 6.86913 0.537455 6.80839C0.562614 6.74765 0.59949 6.69247 0.645978 6.64598L3.29298 3.99998L0.645978 1.35398C0.599415 1.30753 0.562472 1.25236 0.537265 1.19161C0.512059 1.13087 0.499084 1.06575 0.499084 0.999978C0.499084 0.934211 0.512059 0.86909 0.537265 0.808344C0.562472 0.747599 0.599415 0.692424 0.645978 0.645978Z"
        fill={color}
      />
    </svg>
  );
};

export const AttachIcon = () => {
  return (
    <svg
      width="8"
      height="16"
      viewBox="0 0 8 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 2.75C0.5 2.08696 0.763392 1.45107 1.23223 0.982233C1.70107 0.513392 2.33696 0.25 3 0.25C3.66304 0.25 4.29893 0.513392 4.76777 0.982233C5.23661 1.45107 5.5 2.08696 5.5 2.75V11.75C5.5 12.1478 5.34196 12.5294 5.06066 12.8107C4.77936 13.092 4.39782 13.25 4 13.25C3.60218 13.25 3.22064 13.092 2.93934 12.8107C2.65804 12.5294 2.5 12.1478 2.5 11.75V4.75C2.5 4.61739 2.55268 4.49021 2.64645 4.39645C2.74021 4.30268 2.86739 4.25 3 4.25C3.13261 4.25 3.25979 4.30268 3.35355 4.39645C3.44732 4.49021 3.5 4.61739 3.5 4.75V11.75C3.5 11.8826 3.55268 12.0098 3.64645 12.1036C3.74021 12.1973 3.86739 12.25 4 12.25C4.13261 12.25 4.25979 12.1973 4.35355 12.1036C4.44732 12.0098 4.5 11.8826 4.5 11.75V2.75C4.5 2.55302 4.4612 2.35796 4.38582 2.17597C4.31044 1.99399 4.19995 1.82863 4.06066 1.68934C3.92137 1.55005 3.75601 1.43956 3.57403 1.36418C3.39204 1.2888 3.19698 1.25 3 1.25C2.80302 1.25 2.60796 1.2888 2.42597 1.36418C2.24399 1.43956 2.07863 1.55005 1.93934 1.68934C1.80005 1.82863 1.68956 1.99399 1.61418 2.17597C1.5388 2.35796 1.5 2.55302 1.5 2.75V11.75C1.5 12.413 1.76339 13.0489 2.23223 13.5178C2.70107 13.9866 3.33696 14.25 4 14.25C4.66304 14.25 5.29893 13.9866 5.76777 13.5178C6.23661 13.0489 6.5 12.413 6.5 11.75V4.75C6.5 4.61739 6.55268 4.49021 6.64645 4.39645C6.74021 4.30268 6.86739 4.25 7 4.25C7.13261 4.25 7.25979 4.30268 7.35355 4.39645C7.44732 4.49021 7.5 4.61739 7.5 4.75V11.75C7.5 12.6783 7.13125 13.5685 6.47487 14.2249C5.8185 14.8813 4.92826 15.25 4 15.25C3.07174 15.25 2.1815 14.8813 1.52513 14.2249C0.868749 13.5685 0.5 12.6783 0.5 11.75V2.75Z"
        fill="#384555"
      />
    </svg>
  );
};

export const AddTagIcon = () => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.34176 7.89624H0.412964M7.34176 14.825L7.34176 7.89624L7.34176 14.825ZM7.34176 7.89624V0.967438V7.89624ZM7.34176 7.89624L14.2706 7.89624L7.34176 7.89624Z"
        stroke="#F8784D"
        strokeWidth="2"
      />
    </svg>
  );
};

export const BoldIcon = () => {
  return (
    <svg
      width="10"
      height="15"
      viewBox="0 0 10 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0.15564V14.1556H5.04444C6.56667 14.1556 7.77778 13.8003 8.66667 13.0787C9.55556 12.3679 10 11.3772 10 10.0956C10 9.19102 9.81111 8.46949 9.43333 7.93103C9.05556 7.39256 8.43333 7.01564 7.57778 6.82179V6.72487C8.21111 6.61718 8.71111 6.2941 9.08889 5.75564C9.46667 5.21718 9.65556 4.53872 9.65556 3.69872C9.65556 2.47102 9.22222 1.56641 8.35556 1.00641C7.5 0.43564 6.11111 0.15564 4.2 0.15564H0ZM2.85556 5.70179V2.58949H4.36667C5.17778 2.58949 5.77778 2.70795 6.15556 2.9341C6.53333 3.17102 6.72222 3.55872 6.72222 4.08641C6.72222 4.66795 6.54444 5.07718 6.2 5.32487C5.85556 5.57256 5.28889 5.70179 4.52222 5.70179H2.85556ZM2.85556 8.06026H4.63333C6.23333 8.06026 7.03333 8.63102 7.03333 9.7941C7.03333 10.4403 6.84444 10.9249 6.46667 11.2372C6.08889 11.5495 5.51111 11.7003 4.72222 11.7003H2.85556V8.06026Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export const ItalicIcon = () => {
  return (
    <svg
      width="13"
      height="15"
      viewBox="0 0 13 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 2.78064V0.15564H3.71429V2.78064H6.37743L3.72543 11.5306H0V14.1556H9.28571V11.5306H6.62257L9.27457 2.78064H13Z"
        fill="black"
      />
    </svg>
  );
};

export const UnderlineIcon = () => {
  return (
    <svg
      width="10"
      height="16"
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0.15564H10V6.87978C10 9.45047 7.761 11.5349 5 11.5349C2.239 11.5349 0 9.45047 0 6.87978V0.15564H2V6.87978C2 7.52943 2.285 8.15219 2.802 8.63323C3.379 9.17012 4.159 9.46598 5 9.46598C5.841 9.46598 6.621 9.17012 7.198 8.63323C7.715 8.15116 8 7.5284 8 6.87978V0.15564ZM0 12.5694H10V15.1556H0V12.5694Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export const LinkIcon = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.29241 11.3531C9.26108 11.322 9.21878 11.3046 9.1747 11.3046C9.13062 11.3046 9.08832 11.322 9.057 11.3531L6.63624 13.774C5.51545 14.8949 3.62384 15.0137 2.38638 13.774C1.14684 12.5344 1.26558 10.6447 2.38638 9.5238L4.80714 7.10285C4.87172 7.03827 4.87172 6.93201 4.80714 6.86743L3.978 6.03822C3.94667 6.0072 3.90437 5.9898 3.86029 5.9898C3.81621 5.9898 3.77391 6.0072 3.74259 6.03822L1.32183 8.45917C-0.440611 10.2218 -0.440611 13.074 1.32183 14.8345C3.08427 16.595 5.93627 16.5971 7.69663 14.8345L10.1174 12.4135C10.182 12.3489 10.182 12.2427 10.1174 12.1781L9.29241 11.3531ZM14.6797 1.47758C12.9173 -0.285007 10.0653 -0.285007 8.30494 1.47758L5.8821 3.89853C5.85108 3.92986 5.83369 3.97216 5.83369 4.01624C5.83369 4.06033 5.85108 4.10263 5.8821 4.13396L6.70916 4.96108C6.77374 5.02567 6.87998 5.02567 6.94457 4.96108L9.36532 2.54013C10.4861 1.41924 12.3777 1.30049 13.6152 2.54013C14.8547 3.77977 14.736 5.66945 13.6152 6.79034L11.1944 9.21129C11.1634 9.24262 11.146 9.28492 11.146 9.329C11.146 9.37309 11.1634 9.41539 11.1944 9.44672L12.0236 10.2759C12.0881 10.3405 12.1944 10.3405 12.259 10.2759L14.6797 7.85497C16.4401 6.09239 16.4401 3.24016 14.6797 1.47758ZM10.0445 5.24651C10.0131 5.21549 9.97084 5.19809 9.92676 5.19809C9.88268 5.19809 9.84038 5.21549 9.80906 5.24651L5.09046 9.96341C5.05944 9.99474 5.04204 10.037 5.04204 10.0811C5.04204 10.1252 5.05944 10.1675 5.09046 10.1988L5.91543 11.0239C5.98001 11.0885 6.08626 11.0885 6.15084 11.0239L10.8674 6.30698C10.9319 6.2424 10.9319 6.13614 10.8674 6.07155L10.0445 5.24651Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export const ImageIcon = props => {
  const { width = 17, height = 15 } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.502014 2.15564C0.502014 1.62521 0.712728 1.1165 1.0878 0.741426C1.46287 0.366353 1.97158 0.15564 2.50201 0.15564H14.502C15.0324 0.15564 15.5412 0.366353 15.9162 0.741426C16.2913 1.1165 16.502 1.62521 16.502 2.15564V12.1556C16.502 12.6861 16.2913 13.1948 15.9162 13.5699C15.5412 13.9449 15.0324 14.1556 14.502 14.1556H2.50201C1.97158 14.1556 1.46287 13.9449 1.0878 13.5699C0.712728 13.1948 0.502014 12.6861 0.502014 12.1556V2.15564ZM1.50201 11.1556V12.1556C1.50201 12.4209 1.60737 12.6752 1.79491 12.8627C1.98244 13.0503 2.2368 13.1556 2.50201 13.1556H14.502C14.7672 13.1556 15.0216 13.0503 15.2091 12.8627C15.3967 12.6752 15.502 12.4209 15.502 12.1556V8.65564L11.725 6.70864C11.6312 6.66166 11.5251 6.64537 11.4215 6.66206C11.318 6.67875 11.2223 6.72758 11.148 6.80164L7.43801 10.5116L4.77801 8.73964C4.68197 8.6757 4.56678 8.64694 4.45195 8.65824C4.33713 8.66954 4.22975 8.7202 4.14801 8.80164L1.50201 11.1556ZM6.50201 4.65564C6.50201 4.25781 6.34398 3.87628 6.06267 3.59498C5.78137 3.31367 5.39984 3.15564 5.00201 3.15564C4.60419 3.15564 4.22266 3.31367 3.94135 3.59498C3.66005 3.87628 3.50201 4.25781 3.50201 4.65564C3.50201 5.05346 3.66005 5.435 3.94135 5.7163C4.22266 5.9976 4.60419 6.15564 5.00201 6.15564C5.39984 6.15564 5.78137 5.9976 6.06267 5.7163C6.34398 5.435 6.50201 5.05346 6.50201 4.65564Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export const ListOrderedIcon = () => {
  return (
    <svg
      width="25"
      height="18"
      viewBox="0 0 25 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.8064 15.9188C7.8064 15.6896 7.892 15.4698 8.04437 15.3077C8.19675 15.1457 8.40341 15.0546 8.6189 15.0546H23.2439C23.4594 15.0546 23.666 15.1457 23.8184 15.3077C23.9708 15.4698 24.0564 15.6896 24.0564 15.9188C24.0564 16.148 23.9708 16.3678 23.8184 16.5298C23.666 16.6919 23.4594 16.7829 23.2439 16.7829H8.6189C8.40341 16.7829 8.19675 16.6919 8.04437 16.5298C7.892 16.3678 7.8064 16.148 7.8064 15.9188ZM7.8064 9.00552C7.8064 8.77633 7.892 8.55653 8.04437 8.39447C8.19675 8.23241 8.40341 8.14136 8.6189 8.14136H23.2439C23.4594 8.14136 23.666 8.23241 23.8184 8.39447C23.9708 8.55653 24.0564 8.77633 24.0564 9.00552C24.0564 9.23471 23.9708 9.45451 23.8184 9.61657C23.666 9.77863 23.4594 9.86968 23.2439 9.86968H8.6189C8.40341 9.86968 8.19675 9.77863 8.04437 9.61657C7.892 9.45451 7.8064 9.23471 7.8064 9.00552ZM7.8064 2.09225C7.8064 1.86306 7.892 1.64326 8.04437 1.48119C8.19675 1.31913 8.40341 1.22809 8.6189 1.22809H23.2439C23.4594 1.22809 23.666 1.31913 23.8184 1.48119C23.9708 1.64326 24.0564 1.86306 24.0564 2.09225C24.0564 2.32144 23.9708 2.54124 23.8184 2.7033C23.666 2.86536 23.4594 2.95641 23.2439 2.95641H8.6189C8.40341 2.95641 8.19675 2.86536 8.04437 2.7033C7.892 2.54124 7.8064 2.32144 7.8064 2.09225Z"
        fill="#8C8C8C"
      />
      <path
        d="M1.68988 15.6435V14.8733H2.15625C2.50887 14.8733 2.74612 14.6506 2.74612 14.3581C2.74612 14.0575 2.48937 13.8544 2.1595 13.8544C1.79713 13.8544 1.56312 14.1014 1.55337 14.3581H0.594625C0.620625 13.5993 1.20075 13.0793 2.19687 13.0793C3.15237 13.076 3.74712 13.5521 3.752 14.2216C3.75508 14.4513 3.67632 14.6745 3.52983 14.8514C3.38334 15.0282 3.17868 15.1472 2.9525 15.1869V15.2405C3.20894 15.2596 3.44814 15.3769 3.62035 15.5679C3.79256 15.7588 3.88453 16.0088 3.87712 16.2659C3.882 17.132 3.06137 17.5659 2.16925 17.5659C1.10325 17.5659 0.54425 16.9646 0.53125 16.2756H1.477C1.49 16.5649 1.77925 16.7729 2.16275 16.7778C2.5755 16.7778 2.85175 16.5421 2.8485 16.209C2.84525 15.8921 2.59663 15.6435 2.17575 15.6435H1.68825H1.68988ZM1.68337 8.00764H0.701875V7.95076C0.701875 7.28776 1.18125 6.57926 2.25862 6.57926C3.206 6.57926 3.81862 7.10901 3.81862 7.80777C3.81862 8.43989 3.401 8.81039 3.04512 9.18576L2.1725 10.1153V10.164H3.88525V10.9879H0.763625V10.346L2.31875 8.73726C2.543 8.50651 2.79487 8.24326 2.79487 7.91176C2.79487 7.61926 2.556 7.39176 2.23913 7.39176C2.16597 7.38908 2.09304 7.4014 2.02483 7.42797C1.95662 7.45454 1.89457 7.49479 1.84251 7.54624C1.79044 7.5977 1.74947 7.65927 1.7221 7.72716C1.69473 7.79506 1.68155 7.86784 1.68337 7.94101V8.00764ZM3.07275 4.48789H2.04087V1.11439H1.9905L1.01875 1.79689V0.875514L2.04087 0.15564H3.07275V4.48789Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export const ListBulletIcon = () => {
  return (
    <svg
      width="22"
      height="17"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.58105 14.9199C5.58105 14.691 5.66755 14.4715 5.8215 14.3097C5.97546 14.1478 6.18427 14.0569 6.402 14.0569H21.1791C21.3968 14.0569 21.6056 14.1478 21.7596 14.3097C21.9135 14.4715 22 14.691 22 14.9199C22 15.1488 21.9135 15.3683 21.7596 15.5302C21.6056 15.692 21.3968 15.7829 21.1791 15.7829H6.402C6.18427 15.7829 5.97546 15.692 5.8215 15.5302C5.66755 15.3683 5.58105 15.1488 5.58105 14.9199ZM5.58105 8.01573C5.58105 7.78684 5.66755 7.56733 5.8215 7.40548C5.97546 7.24363 6.18427 7.15271 6.402 7.15271H21.1791C21.3968 7.15271 21.6056 7.24363 21.7596 7.40548C21.9135 7.56733 22 7.78684 22 8.01573C22 8.24462 21.9135 8.46413 21.7596 8.62598C21.6056 8.78783 21.3968 8.87875 21.1791 8.87875H6.402C6.18427 8.87875 5.97546 8.78783 5.8215 8.62598C5.66755 8.46413 5.58105 8.24462 5.58105 8.01573ZM5.58105 1.11156C5.58105 0.882669 5.66755 0.663156 5.8215 0.501309C5.97546 0.339461 6.18427 0.248535 6.402 0.248535H21.1791C21.3968 0.248535 21.6056 0.339461 21.7596 0.501309C21.9135 0.663156 22 0.882669 22 1.11156C22 1.34044 21.9135 1.55996 21.7596 1.72181C21.6056 1.88365 21.3968 1.97458 21.1791 1.97458H6.402C6.18427 1.97458 5.97546 1.88365 5.8215 1.72181C5.66755 1.55996 5.58105 1.34044 5.58105 1.11156Z"
        fill="#8C8C8C"
      />
      <path
        d="M2.0208 1.13005C2.0208 1.71668 1.56842 2.19223 1.0104 2.19223C0.45237 2.19223 0 1.71668 0 1.13005C0 0.543425 0.45237 0.0678711 1.0104 0.0678711C1.56842 0.0678711 2.0208 0.543425 2.0208 1.13005Z"
        fill="#8C8C8C"
      />
      <path
        d="M2.06498 8.09325C2.06498 8.67987 1.61261 9.15543 1.05459 9.15543C0.49656 9.15543 0.0441895 8.67987 0.0441895 8.09325C0.0441895 7.50662 0.49656 7.03107 1.05459 7.03107C1.61261 7.03107 2.06498 7.50662 2.06498 8.09325Z"
        fill="#8C8C8C"
      />
      <path
        d="M2.03788 15.0057C2.03788 15.5923 1.58551 16.0678 1.02749 16.0678C0.46946 16.0678 0.0170898 15.5923 0.0170898 15.0057C0.0170898 14.419 0.46946 13.9435 1.02749 13.9435C1.58551 13.9435 2.03788 14.419 2.03788 15.0057Z"
        fill="#8C8C8C"
      />
    </svg>
  );
};

export const AvatarIcon = () => {
  return (
    <svg
      width="38"
      height="39"
      viewBox="0 0 38 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="38"
        height="39"
      >
        <circle cx="19" cy="19.1556" r="19" fill="#D2D8E0" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M19 4.37787C16.0492 4.37787 13.7439 5.51563 12.0841 7.79115C10.4243 10.0667 9.59443 12.9755 9.59443 16.5175C9.57394 20.9183 10.9264 24.4067 13.6517 26.9828C14.0001 27.3263 14.123 27.7663 14.0206 28.303L13.3136 29.8486C13.0882 30.3639 12.7552 30.7664 12.3147 31.0562C11.8741 31.346 10.9469 31.7592 9.53296 32.2959C9.47149 32.3174 8.17541 32.7574 5.64472 33.6161C3.11404 34.4748 1.76673 34.9471 1.6028 35.033C-0.118477 35.7843 -1.2455 36.5679 -1.77828 37.3836C-2.35204 38.736 -2.63892 42.1601 -2.63892 47.6556H40.6389C40.6389 42.1601 40.352 38.736 39.7782 37.3836C39.2454 36.5679 38.1184 35.7843 36.3971 35.033C36.2332 34.9471 34.8859 34.4748 32.3552 33.6161C29.8245 32.7574 28.5285 32.3174 28.467 32.2959C27.0531 31.7592 26.1258 31.346 25.6853 31.0562C25.2447 30.7664 24.9117 30.3639 24.6863 29.8486L23.9794 28.303C23.8769 27.7663 23.9999 27.3263 24.3482 26.9828C27.0736 24.4067 28.426 20.9183 28.4055 16.5175C28.4055 12.9755 27.5756 10.0667 25.9158 7.79115C24.256 5.51563 21.9507 4.37787 19 4.37787Z"
          fill="#1C3B62"
        />
        <circle cx="19" cy="19.1556" r="18.5" stroke="#D2D8E0" />
      </g>
    </svg>
  );
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 13l4 4L19 7"
      />
    </svg>
  );
};

export const ArrowToBottomIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const ArrowToTopIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M14.707 12.707a1 1 0 01-1.414 0L10 9.414l-3.293 3.293a1 1 0 01-1.414-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 010 1.414z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const AngleLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15 19l-7-7 7-7"
      />
    </svg>
  );
};

export const AngleRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 5l7 7-7 7"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
      />
    </svg>
  );
};

export const RefreshIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
      />
    </svg>
  );
};
export const PlusIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.61087 8.12106L8.61087 0.184082M0.890923 8.12106L8.61087 8.12106L0.890923 8.12106ZM8.61087 8.12106L16.3308 8.12106L8.61087 8.12106ZM8.61087 8.12106L8.61087 16.058L8.61087 8.12106Z"
        stroke="white"
        strokeWidth="2"
      />
    </svg>
  );
};

export const TriangleTodoIcon = () => {
  return (
    <svg
      width="29"
      height="53"
      viewBox="5 5 19 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.15369 1.07959L17.8501 25.7615L1.15369 52.3421"
        fill="#F9FAFB"
        stroke="#E5E7EB"
      />
    </svg>
  );
};

export const TriangleInIcon = () => {
  return (
    <svg
      width="29"
      height="53"
      viewBox="5 5 19 45"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.15369 1.07959L17.8501 25.7615L1.15369 52.3421"
        fill="#9CA3AF"
        stroke="#9CA3AF"
      />
    </svg>
  );
};

export const CircleSuccessIcon = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.781738"
        y="0.278809"
        width="18"
        height="18"
        rx="9"
        fill="#31A852"
      />
    </svg>
  );
};

export const CircleFailedIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.677246"
        y="1.61987"
        width="18"
        height="18"
        rx="9"
        fill="#FF3D00"
      />
      <path
        d="M10.8156 5.69627L10.5356 12.4023H8.50561L8.22561 5.69627H10.8156ZM9.55561 15.9863C9.13561 15.9863 8.79027 15.8649 8.51961 15.6223C8.25827 15.3703 8.12761 15.0623 8.12761 14.6983C8.12761 14.3249 8.25827 14.0123 8.51961 13.7603C8.79027 13.5083 9.13561 13.3823 9.55561 13.3823C9.96627 13.3823 10.3023 13.5083 10.5636 13.7603C10.8343 14.0123 10.9696 14.3249 10.9696 14.6983C10.9696 15.0623 10.8343 15.3703 10.5636 15.6223C10.3023 15.8649 9.96627 15.9863 9.55561 15.9863Z"
        fill="white"
      />
    </svg>
  );
};

export const TimingIcon = () => {
  return (
    <svg
      id="Layer_1"
      x="0px"
      y="0px"
      viewBox="0 0 122.88 122.79"
      style={{
        enableBackground: "new 0 0 122.88 122.79",
      }}
      xmlSpace="preserve"
    >
      <g>
        <path d="M84.95,4.57c22.91,9.49,37.93,31.86,37.93,56.78c0,16.97-6.88,32.33-17.99,43.45c-11.12,11.12-26.48,18-43.45,18 c-16.96,0-32.32-6.88-43.44-18C6.88,93.67,0,78.31,0,61.35C0,36.62,15.37,13.55,38.24,4.17C52.4-1.63,70.85-1.27,84.95,4.57 L84.95,4.57z M51.99,59.71c0.77-0.84,1.7-1.56,2.71-2.11V30.87c0-2.73,2.21-4.93,4.93-4.93c2.73,0,4.94,2.21,4.94,4.93V57.6 c1.8,0.97,3.28,2.45,4.25,4.25H89.1c2.73,0,4.93,2.21,4.93,4.93s-2.21,4.93-4.93,4.93H68.83c-1.76,3.28-5.21,5.51-9.19,5.51 c-3.13,0-5.93-1.38-7.84-3.56C48.25,69.61,48.42,63.57,51.99,59.71L51.99,59.71z M97.91,25.84C72.46,0.39,29.53,7.58,14.64,39.64 c-3.06,6.6-4.77,13.95-4.77,21.7c0,14.25,5.77,27.14,15.1,36.47s22.23,15.11,36.47,15.11c14.24,0,27.14-5.78,36.47-15.11 c9.33-9.33,15.11-22.23,15.11-36.47c0-8.08-1.86-15.73-5.17-22.54C105.33,33.65,101.97,29.9,97.91,25.84L97.91,25.84z" />
      </g>
    </svg>
  );
};

export const ArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-left w-4 h-4"
    >
      <polyline points="15 18 9 12 15 6"></polyline>
    </svg>
  );
};

export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-chevron-right w-4 h-4"
    >
      <polyline points="9 18 15 12 9 6"></polyline>
    </svg>
  );
};

export const DeleteIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      width="20px"
      height="20px"
    >
      <path
        fill="#E04F5F"
        d="M504.1,256C504.1,119,393,7.9,256,7.9C119,7.9,7.9,119,7.9,256C7.9,393,119,504.1,256,504.1C393,504.1,504.1,393,504.1,256z"
      />
      <path
        fill="#FFF"
        d="M285,256l72.5-84.2c7.9-9.2,6.9-23-2.3-31c-9.2-7.9-23-6.9-30.9,2.3L256,222.4l-68.2-79.2c-7.9-9.2-21.8-10.2-31-2.3c-9.2,7.9-10.2,21.8-2.3,31L227,256l-72.5,84.2c-7.9,9.2-6.9,23,2.3,31c4.1,3.6,9.2,5.3,14.3,5.3c6.2,0,12.3-2.6,16.6-7.6l68.2-79.2l68.2,79.2c4.3,5,10.5,7.6,16.6,7.6c5.1,0,10.2-1.7,14.3-5.3c9.2-7.9,10.2-21.8,2.3-31L285,256z"
      />
    </svg>
  );
};

export const ArrowRightLongIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      />
    </svg>
  );
};

export const ArrowLeftLongIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-4 w-4"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M14 5l7 7m0 0l-7 7m7-7H3"
      />
    </svg>
  );
};

export const ArrowExpandIcon = ({ width, height, color = "#7D8085" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      width={width || 8}
      height={height || 8}
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        // strokeWidth={2}
        d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
        fill={color}
      />
    </svg>
  );
};

export const EditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
      />
    </svg>
  );
};

export const ArrowToLeft = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 19l-7-7m0 0l7-7m-7 7h18"
      />
    </svg>
  );
};

export const TrashIconOutline = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
      />
    </svg>
  );
};

export const BuildingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
      />
    </svg>
  );
};

export const ChevronDoubleRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className="w-4 h-4"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};
