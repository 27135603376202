import { createSelector, createSlice } from "@reduxjs/toolkit";
import { get } from "src/utils/axios";
import toast from "react-hot-toast";

const initialState = {
  currentOffice: {
    id: null,
    attributes: {},
    relationships: {},
    tags: [],
    tasks: [],
    bills: [],
    issues: [],
    topics: [],
  },
  offices: [],
};

const slice = createSlice({
  name: "office",
  initialState,
  reducers: {
    setCurrentOffice(state, action) {
      state.currentOffice.id = action.payload ? action.payload.id : null;
      state.currentOffice.attributes = action.payload
        ? action.payload.attributes
        : {};
      state.currentOffice.relationships = action.payload
        ? action.payload.relationships
        : {};
    },
    setConnectEmail(state, action) {
      state.currentOffice.attributes.connect_email =
        action.payload.connectEmail;
    },
    loadOffices(state, action) {
      state.offices = action.payload;
    },
    setTasks(state, action) {
      state.currentOffice.tasks = action.payload;
    },
    setTags(state, action) {
      state.currentOffice.tags = action.payload;
    },
    setBills(state, action) {
      state.currentOffice.bills = action.payload;
    },
    setIssues(state, action) {
      state.currentOffice.issues = action.payload;
    },
    setTopics(state, action) {
      state.currentOffice.topics = action.payload;
    },
  },
});

export const reducer = slice.reducer;
export const {
  setCurrentOffice,
  setConnectEmail,
  loadOffices,
  setTasks,
  setTags,
  setBills,
  setIssues,
  setTopics,
} = slice.actions;

export const setOffice = office => async dispatch => {
  dispatch(slice.actions.setCurrentOffice(office));
  localStorage.setItem("currentOffice", JSON.stringify(office));
};

export const initOfficeList = () => async dispatch => {
  try {
    const response = await get("api/v1/offices");
    if (response.status === 200) {
      const offices = response.data.data;
      const offices_with_details = await Promise.all(
        response.data.data.map(async obj => {
          const res = await get(`api/v1/offices/${obj.id}`);
          return res.data.data;
        })
      );

      dispatch(slice.actions.loadOffices(offices_with_details));

      const savedOffice = localStorage.getItem("currentOffice");
      if (savedOffice) {
        dispatch(
          setOffice(
            offices_with_details.find(
              office => office.id === JSON.parse(savedOffice).id
            )
          )
        );
      } else {
        const defaultOffice = offices_with_details[0];
        dispatch(setOffice(defaultOffice));
      }
    }
  } catch (error) {
    toast("Something broke, please retry your request…");
  }
};

export const initPublicOfficeList = () => async dispatch => {
  try {
    const response = await get("public/api/v1/public_offices");
    if (response.status === 200) {
      const offices = response.data.data;

      dispatch(slice.actions.loadOffices(offices));
      const savedOffice = localStorage.getItem("currentOffice");

      if (savedOffice) {
        dispatch(setOffice(JSON.parse(savedOffice)));
      }
    }
  } catch (error) {
    toast("Something broke, please retry your request…");
  }
};

export const fetchTasks = officeId => async dispatch => {
  try {
    if (officeId) {
      const res = await get(`api/v1/tasks?office_id=${officeId}`);
      dispatch(setTasks(res.data.data));
    }
  } catch (error) {
    toast.error("Something broke, please retry your request…");
  }
};

export const fetchTags = officeId => async dispatch => {
  try {
    if (officeId) {
      const res = await get(`api/v1/tags?office_id=${officeId}`);
      dispatch(setTags(res.data.data));
    }
  } catch (error) {
    toast.error("Something broke, please retry your request…");
  }
};

export const fetchBills = officeId => async dispatch => {
  try {
    if (officeId) {
      const res = await get(`api/v1/bills?office_id=${officeId}`);
      dispatch(setBills(res.data.data));
    }
  } catch (error) {
    toast.error("Something broke, please retry your request…");
  }
};

export const fetchIssues = officeId => async dispatch => {
  try {
    if (officeId) {
      const res = await get(`api/v1/issues?office_id=${officeId}`);
      dispatch(setIssues(res.data.data));
    }
  } catch (error) {
    toast.error("Something broke, please retry your request…");
  }
};

export const fetchTopics = officeId => async dispatch => {
  try {
    if (officeId) {
      const res = await get(`api/v1/topics?office_id=${officeId}`);
      dispatch(setTopics(res.data.data));
    }
  } catch (error) {
    toast.error("Something broke, please retry your request…");
  }
};

export const officesSelector = createSelector(
  state => state.office,
  office => office.offices
);

export const currentOfficeSelector = createSelector(
  state => state.office,
  office => office.currentOffice
);

export const currentOfficeIdSelector = createSelector(
  state => state.office,
  office => office.currentOffice.id
);

export const tasksSelector = createSelector(
  state => state.office,
  office => office.currentOffice?.tasks
);

export const tagsSelector = createSelector(
  state => state.office,
  office => office.currentOffice?.tags
);

export const billsSelector = createSelector(
  state => state.office,
  office => office.currentOffice?.bills
);

export const issuesSelector = createSelector(
  state => state.office,
  office => office.currentOffice?.issues
);

export const topicsSelector = createSelector(
  state => state.office,
  office => office.currentOffice?.topics
);
