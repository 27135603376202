import React from "react";

import { BrowserRouter as Router } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import routes, { renderRoutes } from "src/routes";
import { AuthProvider } from "src/contexts/JWTAuthContext";

const App = () => {
  return (
    <Router>
      <AuthProvider>{renderRoutes(routes)}</AuthProvider>
      <Toaster
        toastOptions={{
          success: {
            iconTheme: {
              primary: "white",
              secondary: "#10B981",
            },
            style: {
              background: "#10B981", // tailwind bg-green-500
              color: "#FFFFFF",
              fontSize: "15px",
            },
          },
          error: {
            iconTheme: {
              primary: "white",
              secondary: "#EF4444",
            },
            style: {
              background: "#EF4444", // tailwind bg-red-500
              color: "#FFFFFF",
              fontSize: "15px",
            },
          },
        }}
      />
    </Router>
  );
};

export default App;
