import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loading: false,
  platformLoading: false,
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setPlatformLoading(state, action) {
      state.platformLoading = action.payload;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
