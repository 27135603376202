import React, { Fragment, useState } from "react";
import useAuth from "src/hooks/useAuth";
import "./navbar.css";
import { useHistory } from "react-router-dom";
import logo from "src/assets/logo.png";
import { LogoutIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { currentOfficeSelector } from "src/slices/office";
import Menu from "./components/Menu";
import PublicMenu from "./components/PublicMenu";
import PublicPopup from "src/components/PublicPopup";

const NavBar = () => {
  const { logout, user } = useAuth();
  const history = useHistory();
  const currentOffice = useSelector(currentOfficeSelector);
  const currentOfficeId = currentOffice.id;
  const role = currentOffice.attributes.role;
  const tier = currentOffice.attributes.tier;
  const isForecasts = currentOffice.attributes.forecasts;

  const [isOpenModal, setIsOpenModal] = useState(false);

  const connectEmail = useSelector(
    state => state.office.currentOffice.attributes.connect_email
  );

  const handleLogout = () => {
    logout().then(() => {
      console.log("Log out!");
    });
  };

  const onPublicMenuClick = () => {
    setIsOpenModal(true);
  };

  const onPublicOffices = () => {
    if (user) history.push("/public_offices");
    else history.push("/discover");
  };

  const onPublicOfficePosts = () => {
    // if (user) history.push("/public_offices");
    history.push(`/public_offices/${currentOfficeId}/posts`);
  };

  let navigationBar;
  if (user) {
    navigationBar = (
      <Fragment>
        <div className="flex flex-col">
          <div className="mb-8 px-4">
            <img src={logo} width="120" />
          </div>
          {role !== "subscriber" && (
            <div>
              {/* {connectEmail && (
                <Fragment>
                  <Menu title="Home" />
                  {tier && tier !== "free" && <Menu title="Emails" />}
                </Fragment>
              )} */}
              <Menu title="Feed" />
              {tier && tier !== "free" && (
                <Fragment>
                  <Menu title="Messages" />
                  <Menu title="Tasks" />
                </Fragment>
              )}
              {connectEmail && (
                <Fragment>
                  <Menu title="Workflows" />
                  <Menu title="Templates" />
                </Fragment>
              )}
              <Menu title="Posts" />
              <Menu title="People" />
              <Menu title="Bills" />
              <Menu title="Topics" />
              <Menu title="Subtopics" />
              <Menu title="Contacts" />
              {isForecasts && <Menu title="Forecasts" />}
            </div>
          )}
          {role === "subscriber" && (
            <Fragment>
              <Menu title="Posts" />
              <Menu title="Discover" />
            </Fragment>
          )}
        </div>
        <div>
          {role !== "subscriber" && <Menu title="Office" />}
          <Menu title="Notifications" />
          <Menu title="Discover" />
          <Menu title="Account" />
          <div
            className="px-4 py-1 cursor-pointer hover:bg-purple-100 rounded-r-sm flex items-center space-x-1"
            onClick={handleLogout}
          >
            <div className="h-4 w-4">
              <LogoutIcon />
            </div>
            <span>Logout</span>
          </div>
        </div>
      </Fragment>
    );
  } else {
    navigationBar = (
      <Fragment>
        <div className="flex flex-col">
          <div className="mb-8 px-4">
            <img src={logo} width="120" />
          </div>
          <PublicMenu title="Data" onMenuClick={onPublicMenuClick} />
          <PublicMenu title="Contacts" onMenuClick={onPublicMenuClick} />
          {currentOfficeId && (
            <PublicMenu title="Posts" onMenuClick={onPublicOfficePosts} />
          )}
          <PublicMenu title="Office" onMenuClick={onPublicMenuClick} />
          <PublicMenu title="Notifications" onMenuClick={onPublicMenuClick} />
        </div>
        <div className="mb-10">
          <PublicMenu title="Discover" onMenuClick={onPublicOffices} />
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className="w-40 flex flex-col justify-between items-start h-full fixed py-6 bg-white border-r-1 border-gray-100">
        {navigationBar}
      </div>
      <PublicPopup isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </Fragment>
  );
};

export default NavBar;
