import React, { useState, useEffect } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { get, post } from "src/utils/axios";
import { useSelector } from "react-redux";
import { currentOfficeIdSelector } from "src/slices/office";
import toast from "react-hot-toast";
import { ContactTags } from "../../../InboxDetail";

const MessageContactDetail = props => {
  const { handleUpdateValue, valueList } = props;
  const currentOfficeId = useSelector(currentOfficeIdSelector);
  const initValues = valueList.map(value=>Object.assign({}, {value:value.id, label:value.name}))
  const [selected, setSelected] = useState(initValues)

  const customStyles = {
    control: provided => ({
      ...provided,
      height: 34,
      minHeight: 34,
      fontSize: 14,
    }),
    placeholder: provided => ({
        ...provided,
        fontSize: 14,
        paddingBottom: 3
    }),
    singleValue: provided => ({
        ...provided,
        paddingBottom: 3
    })
  };

  const onChangeHandler = (selected_options, actionMeta) => {
        
    switch (actionMeta.action) {
      case "create-option":
        var created_value = actionMeta.option.value 
        handleCreateOption(created_value, selected_options);
        break;
      case "select-option":
        handleSelectOption(selected_options);
        break;
      case "remove-value":
        handleSelectOption(selected_options);
        break;
      default:
        break;
    }
  }

  const handleSelectOption = selected_options => {
    setSelected(selected_options);
    let opts = selected_options.map(opt=>Object.assign({}, {id:opt.value, name:opt.label}))
    props.handleUpdateValue(selected_options)
  }

  const handleCreateOption = async (created_value, selected_options) => {
    try {
      let response = await post(`api/v1/tags?office_id=${currentOfficeId}`, {name: created_value});
      let result = response.data.data
      setSelected(
        selected.concat([
          {
            value: result.id,
            label: created_value,
          },
        ])
      );

      let opts = selected_options.map((option) => {
        if (option.label === created_value) 
          return Object.assign({}, {id: result.id, name:created_value})
        else
          return Object.assign({}, {id: option.value, name:option.label})
      })
      props.handleUpdateValue(opts)

    } catch (error) {
        console.log(`creating tag error`, error);
        toast("Something broke, please retry your request…");
    }
  }

  const lookupOptions = inputValue => {
    return new Promise(resolve => {
      resolve(searchOptions(inputValue));
    });
  };

  const searchOptions = async term => {
    try {
      if (currentOfficeId) {
        const response = await get( `api/v1/tags?office_id=${currentOfficeId}&&query=${term}`);
    
        const results = response.data.data;
        let options = [];
        if (results.length > 0) {
          options = results.map(option => {
            return {
              value: option.id,
              label: option.attributes.name,
            };
          });
          return options;

        } else {
          return [];
        }
      }
    } catch (e) {
      return [];
    }
  };

  return (
    <div>
      <div className="text-sm p-1">Tag</div>
      <div className="w-full">
        <AsyncCreatableSelect
          styles={customStyles}
          isMulti
          cacheOptions
          value={selected}
          name="messageTag"
          loadOptions={lookupOptions}
          className="basic-multi-select"
          classNamePrefix="select"
          placeholder="Select tag(s)"
          onChange={onChangeHandler}
          isClearable
        />
      </div>
    </div>
  );
};

export default MessageContactDetail;
