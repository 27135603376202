import React from "react";
import AsyncSelect from "react-select/async";
import { get, post } from "src/utils/axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const ContactBills = props => {
  const { optionList, valueList, source = "primary" } = props;
  const office = useSelector(state => state.office);
  const { thread_id } = useParams();

  const handleChangeOption = option => {
    const { handleUpdateValue } = props;
    let params;
    if (option) {
      params = [option.value];
    } else {
      params = [];
    }
    if (source === "primary") {
      handleValue(params, option);
    } else if (source === "no-api") {
      handleUpdateValue(option);
    }
  };

  const handleValue = async param => {
    const officeId = office.currentOffice.id;
    const { handleUpdateValue } = props;
    try {
      const result = await post(
        `api/v1/threads/${thread_id}/bills?office_id=${officeId}`,
        {
          bill_ids: param,
        }
      );
      handleUpdateValue(result.data.data);
    } catch (error) {
      console.log("error --- ", error);
      toast("Something broke, please retry your request…");
    }
  };

  const options = optionList.map(item => {
    return {
      value: item.id,
      label: item.attributes.name,
    };
  });

  const value =
    valueList && valueList.length > 0
      ? source === "primary"
        ? {
            value: valueList[0].attributes.bill.id,
            label: valueList[0].attributes.bill.name,
          }
        : {
            value: valueList[0].id,
            label: valueList[0].attributes?.name,
          }
      : null;

  const customStyles = {
    option: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    input: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };

  const searchBill = async term => {
    try {
      const response = await get(
        `api/v1/bills?query=${term}&office_id=${office.currentOffice.id}`
      );

      const results = response.data.data;
      let bills = [];
      if (results.length > 0) {
        bills = results.map(bill => {
          return {
            value: bill.id,
            label: bill.attributes.name,
          };
        });

        return bills;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const lookupBills = inputValue => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(searchBill(inputValue));
      }, 250);
    });
  };

  return (
    <AsyncSelect
      name="bills"
      cacheOptions
      defaultOptions={options}
      className="basic-multi-select"
      classNamePrefix="select"
      styles={customStyles}
      onChange={handleChangeOption}
      loadOptions={lookupBills}
      value={value}
      placeholder="Select Bill"
      isClearable={true}
    />
  );
};

export default ContactBills;
