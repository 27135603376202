import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pageTitle: "",
  sectionLinks: [], // [{ name: , link: }, {...}]
};

const global = createSlice({
  name: "global",
  initialState,
  reducers: {
    setSectionHeader(state, action) {
      state.pageTitle = action.payload.pageTitle;
      state.sectionLinks = action.payload.sectionLinks || [];
    },
  },
});

export const { setSectionHeader } = global.actions;

export const pageTitleSelector = state => state.global.pageTitle;
export const sectionLinksSelector = state => state.global.sectionLinks;

export const reducer = global.reducer;
