import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import "./RichTextEditor.css";

const RichTextEditor = props => {
  const {
    isClearedEditor,
    setIsClearedEditor,
    setEmailBody,
    content = null,
    body = null,
  } = props;

  return (
    <ReactQuill
      placeholder="Type here.."
      className="border-0 font-normal"
      theme="snow"
      value={body}
      onChange={setEmailBody}
    />
  );
};

export default RichTextEditor;
