import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useSelector } from "react-redux";
import { get } from "src/utils/axios";

const TaskUsers = props => {
  const office = useSelector(state => state.office);
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    if (office?.currentOffice?.id) {
      handleLoadUsers();
    }
  }, [office]);

  const handleLoadUsers = async () => {
    try {
      let result = await get(
        `api/v1/office_users?office_id=${office.currentOffice.id}`
      );
      if (result?.data) {
        const users = [
          ...(result.data.admins || []),
          ...(result.data.members || []),
        ];
        setUserList(users);
      }
    } catch (error) {
      console.log("user list load faile", error);
    }
  };

  const handleChangeOption = newValue => {
    const { handleUpdateData } = props;
    const bills = userList.filter(item => {
      const optionItem = newValue.find(
        optionItem => optionItem.value === item.id
      );
      if (optionItem) {
        return true;
      } else {
        return false;
      }
    });
    handleUpdateData(bills);
  };

  const options = userList.map(item => {
    return {
      value: item.id,
      label: `${item.name} - ${item.role}`,
    };
  });

  const value =
    props.data && props.data.length > 0
      ? props.data.map(item => ({
          value: item.id,
          label: `${item.name}`,
        }))
      : null;

  const customStyles = {
    option: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    input: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };

  return (
    <Select
      isMulti
      value={value}
      styles={customStyles}
      name="users"
      options={options}
      onChange={handleChangeOption}
      className="basic-multi-select"
      classNamePrefix="select"
      isClearable={true}
    />
  );
};

export default TaskUsers;
