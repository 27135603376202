import React, { Fragment, useEffect, useState, useRef } from "react";
import { get, post } from "src/utils/axios";
import { useSelector } from "react-redux";
import { currentOfficeSelector } from "src/slices/office";
import toast from "react-hot-toast";
import UpdatedTime from "src/components/UpdatedTime";
import InfiniteScroll from "react-infinite-scroll-component";
import useAuth from "src/hooks/useAuth";

const NOTI_PER_PAGE = 15;

const NotificationPanel = props => {
  const { isShow, onClose, onRefresh, onToPost } = props;

  const currentOffice = useSelector(currentOfficeSelector);
  const { user } = useAuth();

  const [notifications, setNotifications] = useState([]);
  const [curPage, setCurPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [notiItemHeight, setNotiItemHeight] = useState(50);
  const [infiniteScrollHeight, setInfiniteScrollHeight] = useState(800);

  const ref = useRef(null);

  useEffect(() => {
    let intervalId;
    let timer;
    if (user) {
      // instant loading once component is mounted
      // getNotifications();
      timer = setTimeout(getNotifications, 3000);
      // // refresh list of notifications on every 1 min
      intervalId = setInterval(getLatestNotifications, 60000);
    }
    // binding click event to document element
    document.addEventListener("mousedown", Clickout);

    const divRoot = document.getElementById("root");

    setNotiItemHeight(Math.round(divRoot.clientHeight / NOTI_PER_PAGE));
    setInfiniteScrollHeight(window.innerHeight - 130);

    function Resize() {
      setNotiItemHeight(Math.round(divRoot.clientHeight / NOTI_PER_PAGE));
      setInfiniteScrollHeight(window.innerHeight - 130);
    }

    // binding resize event to document element
    window.addEventListener("resize", Resize);

    // clear interval function to prevent memory leak
    return () => {
      document.removeEventListener("mousedown", Clickout);
      window.removeEventListener("resize", Resize);
      clearInterval(intervalId);
      clearTimeout(timer);
    };
  }, []);

  const Clickout = eve => {
    // check if clickin event is out of notification panel, if so, then close notification panel
    if (ref.current && !ref.current.contains(eve.target)) {
      onClose(false);
    }
  };

  useEffect(() => {
    // When clicking bell icon, refreshing notification
    if (isShow) getLatestNotifications();
  }, [isShow]);

  const getUniqueListBy = (arr, key) => {
    return [...new Map(arr.map(item => [item[key], item])).values()];
  };

  const getLatestNotifications = async () => {
    try {
      const response = await get(
        `api/v1/notifications?per_page=${NOTI_PER_PAGE}&page=1`
      );
      const result = response.data;

      setNotifications(result.data);
      setPagination(result.meta.pagination);
      // sending the unread number to parent component
      const unreadNum = result.meta.data.unread;
      onRefresh(unreadNum);

      if (curPage < result.meta.pagination.total_pages) {
        setCurPage(2);
        setHasMore(true);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      toast.error("Fetching notifications error.\n", error.toString());
    }
  };

  const getNotifications = async () => {
    try {
      const response = await get(
        `api/v1/notifications?per_page=${NOTI_PER_PAGE}&page=${curPage}`
      );
      const result = response.data;

      if (notifications.length < result.meta.pagination.total_objects) {
        // Append notifications
        setNotifications(
          getUniqueListBy(notifications.concat(result.data), "id")
        );
      }

      setPagination(result.meta.pagination);
      // sending the unread number to parent component
      const unreadNum = result.meta.data.unread;
      onRefresh(unreadNum);

      // Set next page
      if (curPage < result.meta.pagination.total_pages) {
        setCurPage(curPage + 1);
        setHasMore(true);
      }
      if (notifications.length == result.meta.pagination.total_objects) {
        setCurPage(1);
        setHasMore(false);
      } else {
        setHasMore(true);
      }
    } catch (error) {
      toast.error("Fetching notifications error.\n", error.toString());
    }
  };

  const onNotificationClick = async notification => {
    // modify the read attribute and update the notifcations state
    const newNotifications = notifications.map(noti => {
      if (noti.id === notification.id)
        return {
          ...noti,
          attributes: {
            ...noti.attributes,
            read: true,
          },
        };
      return noti;
    });
    setNotifications(newNotifications);

    // sending request to server
    const payload = {
      notification_ids: [notification.id],
    };
    try {
      await post(
        `api/v1/notifications/mark_as_read?office_id=${currentOffice.id}`,
        payload
      );

      const post_id = notification.attributes.post_id;
      const office_id = notification.attributes.office_id;
      onToPost({ office_id, post_id });
    } catch (error) {
      toast.error("Marking as read failed.\n", error.toString());
    }
  };

  const onReadAll = async () => {
    const newNotifications = notifications.map(noti => {
      return {
        ...noti,
        attributes: {
          ...noti.attributes,
          read: true,
        },
      };
    });
    setNotifications(newNotifications);
    onRefresh(0);
    try {
      await post(
        `api/v1/notifications/mark_all_as_read?office_id=${currentOffice.id}`
      );
    } catch (error) {
      toast.error("Marking as read failed.\n", error.toString());
    }
  };

  return (
    <Fragment>
      {/* {isShow && (
        <button
          className="flex text-2xl text-black items-center cursor-pointer fixed right-5 top-16 z-50"
          onClick={() => onClose(false)}
        >
          <CloseIcon />
        </button>
      )} */}
      <div
        className="shadow-md border-1 border-slate-700 bg-white top-0 right-0 w-1/3 py-4 pt-20 fixed h-full ease-in-out duration-300"
        style={{ transform: isShow ? "" : "translateX(1000px)" }}
        ref={ref}
      >
        <div className="flex flex-row row-nowrap justify-between items-center mb-4 px-6">
          <div className="text-xl font-bold">Notifications</div>
          <a
            className="text-sm text-slate-900 font-semibold hover:text-black hover:font-medium underline cursor-pointer"
            onClick={onReadAll}
          >
            Mark all as read
          </a>
        </div>
        <div
          style={{
            height: `${infiniteScrollHeight}px`,
            paddingBottom: "40px",
            marginRight: "50px",
          }}
          className="overflow-auto divide-y divide-slate-800 px-6 cursor-pointer w-full"
          id="infinitescroll-parent"
        >
          <InfiniteScroll
            dataLength={notifications.length}
            next={getNotifications}
            hasMore={hasMore}
            scrollableTarget="infinitescroll-parent"
            loader={<h4>Loading...</h4>}
            endMessage={
              <p style={{ textAlign: "center" }}>
                <b>Yay! You have seen it all</b>
              </p>
            }
          >
            {notifications.map(notification => {
              const message_parts = notification.attributes.message.split("|");
              const tag_name = message_parts[2].trim();
              const who = message_parts[0].trim();
              const title = message_parts[1].trim();
              const isRead = notification.attributes.read;

              return (
                <div
                  key={notification.id}
                  className={`p-6 pb-0 ${isRead ? "" : "bg-blue-50"}`}
                  onClick={() => onNotificationClick(notification)}
                >
                  <div className="flex flex-row row-nowrap items-baseline justify-between text-sm">
                    <div className="flex space-x-1 items-baseline">
                      <div className="font-semibold antialiased">{who}</div>
                      <div>posted</div>
                      <div className="font-semibold">{title}</div>
                    </div>
                  </div>
                  <div className="flex flex-row row-nowrap items-baseline space-x-1 text-slate-400 text-xs">
                    <UpdatedTime
                      timestamp={notification.attributes.created_at}
                    />
                    <div>&middot;</div>
                    <div>{tag_name}</div>
                  </div>
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </div>
    </Fragment>
  );
};

export default NotificationPanel;
