import React, { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import GlobalAddDropdown from "src/components/GlobalAddDropdown";
import { useDispatch, useSelector } from "react-redux";
import {
  currentOfficeSelector,
  initOfficeList,
  initPublicOfficeList,
  officesSelector,
  setOffice,
} from "src/slices/office";
import "./navbar.css";
import _ from "lodash";
import { useHistory } from "react-router";
import PublicPopup from "src/components/PublicPopup";
import GlobalSearch from "src/components/GlobalSearch";

const TopBar = props => {
  const { user } = useAuth();
  const dispatch = useDispatch();
  const history = useHistory();
  const offices = useSelector(officesSelector);
  const currentOffice = useSelector(currentOfficeSelector);

  const { onBellClicked, notiUnreadNum, postObj } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);

  useEffect(() => {
    if (user) dispatch(initOfficeList());
    else dispatch(initPublicOfficeList());
  }, []);

  const changeOffice = officeId => {
    if (officeId < 0) {
      history.push("/office/setup");
      return;
    }

    if (officeId != currentOffice.id) {
      dispatch(setOffice(offices.find(office => office.id === officeId)));
      if (user) history.push("/posts");
      else history.push(`/public_offices/${officeId}/posts`);
    }
  };

  useEffect(() => {
    if (postObj.office_id && postObj.post_id) {
      if (postObj.office_id.toString() !== currentOffice.id) {
        dispatch(
          setOffice(
            offices.find(office => office.id === postObj.office_id.toString())
          )
        );
      }
      history.push(`/posts/${postObj.post_id}/detail?oid=${postObj.office_id}`);
    }
  }, [postObj]);

  const onBellIconClicked = () => {
    if (user) onBellClicked();
    else setIsOpenModal(true);
  };

  return (
    <div className="topbar h-16 flex justify-between items-center px-8 z-10 bg-white fixed border-gray-300 border-b-1">
      <div className="flex items-center justify-start space-x-4 w-2/3">
        <div>
          {offices.length === 0 ? (
            <div>{currentOffice?.attributes?.name}</div>
          ) : (
            <select
              className="border-none focus:border-none focus:border-0"
              onChange={e => changeOffice(e.target.value)}
              value={currentOffice.id === null ? "" : currentOffice.id}
              disabled={user ? null : true}
            >
              {offices.map(office => (
                <option key={office.id} value={office.id}>
                  {office.attributes.name}
                </option>
              ))}
              {user && (
                <option key={-999} value="-999">
                  Setup a new Office
                </option>
              )}
            </select>
          )}
        </div>
        <div className="flex w-full space-x-6">
          <GlobalAddDropdown onClick={() => setIsOpenModal(true)} />
          <div className="w-full">
            <GlobalSearch />
          </div>
        </div>
      </div>
      <div className="flex items-center justify-end space-x-4">
        <span
          className="relative inline-block mx-3 cursor-pointer"
          onClick={onBellIconClicked}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-7 h-7 text-gray-700"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
            />
          </svg>
          {notiUnreadNum !== 0 && (
            <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
              {notiUnreadNum}
            </span>
          )}
        </span>
        {user ? (
          <a title="My Account" href="/account">
            <div className="w-8 h-8 rounded-full bg-brand-default text-white flex items-center justify-center border-0">
              {user.attributes.first_name[0]}
              {user.attributes.last_name[0]}
            </div>
          </a>
        ) : (
          <div className="mt-2">
            <button
              type="button"
              className="text-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-1.5 text-center mr-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-600 dark:focus:ring-blue-800"
              onClick={() => history.push("/login")}
            >
              Login
            </button>
            <button
              type="button"
              className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={() => history.push("/register")}
            >
              Register
            </button>
            <button
              type="button"
              className="text-white bg-red-800 hover:bg-red-900 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2 mr-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
              onClick={() =>
                (window.location.href = "https://www.legislata.com/welcome")
              }
            >
              Learn More &rarr;
            </button>
          </div>
        )}
      </div>
      <PublicPopup isOpen={isOpenModal} onClose={() => setIsOpenModal(false)} />
    </div>
  );
};

export default TopBar;
