import React, { useEffect, useState } from "react";
import ComposeOrReply from "./ComposeOrReply";

const ComposeNewEmail = ({ key, onClose }) => {
  const [close, setClose] = useState(false);

  useEffect(() => {
    setClose(false);
  }, [key]);

  useEffect(() => {
    if (close) {
      onClose();
    }
  }, [close]);

  if (close) return null;

  return (
    <div
      key={key}
      className="fixed flex flex-col bottom-0 right-0 bg-white z-50 border-1 border-gray-200 shadow-lg rounded-t-lg"
    >
      <div className="w-full py-3 bg-blue-700 flex justify-between items-center px-4 text-white text-sm font-semibold">
        <div>Send a new email</div>
        <div>
          <button className="text-white text-sm" onClick={() => setClose(true)}>
            Close
          </button>
        </div>
      </div>
      <div className="p-4 h-full">
        <ComposeOrReply onSend={() => setClose(true)} />
      </div>
    </div>
  );
};

export default ComposeNewEmail;
