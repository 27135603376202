import React, { useState } from "react";
// import CreatableSelect from "react-select/creatable";
import AsyncCreatableSelect from "react-select/async-creatable";
import { useSelector } from "react-redux";
import { currentOfficeIdSelector } from "src/slices/office";
import { get, post } from "src/utils/axios";
import ContactForm from "./ContactForm";
import {
  MESSAGE_TYPE_EDIT,
  FORM_TYPE_CREATE,
  FORM_TYPE_NONE,
} from "src/assets/consts/message";

const MessageContactForm = props => {
  const currentOfficeId = useSelector(currentOfficeIdSelector);
  const { contactList, selectContact, handleContactList, handleSelectContact } =
    props;
  const [searchStr, setSearchStr] = useState("");
  const [formType, setFormType] = useState(FORM_TYPE_NONE);
  const [selected, setSelected] = useState({value: "", label: ""})
  const handleChange = (newValue, actionMeta) => {
    // const { handleUpdateValue } = props;
    switch (actionMeta.action) {
      case "create-option":
        handleCreateOption(newValue);
        break;
      case "select-option":
        handleSelectOption(newValue);
        // handleUpdateValue(newValue);
        break;
      case "clear":
        handleRemoveOption();
        break;
      default:
        break;
    }
  };

  const handleSelectOption = value => {
    setSelected(value)
    setFormType(FORM_TYPE_NONE);
    const contact = contactList.find(item => item.id === value.value);
    handleSelectContact(contact);
  };

  const handleCreateOption = value => {
    setFormType(FORM_TYPE_CREATE);
    setSelected(value)
  }

  const handleRemoveOption = () => {
    handleSelectContact(null);
    setFormType(FORM_TYPE_NONE);
    setSelected({value: "", label: ""})
  }

  const customStyles = {
    container: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
      border: 0,
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    input: (provided, state) => ({
      ...provided,
      fontSize: "1rem",
      borderRadius: 0,
      boxShadow: "none",
      borderWidth: 0,
      outline: "none !important",
      "&:focus": {
        outline: "none !important",
        borderColor: "red",
      },
      "&:active": {
        outline: "none !important",
        borderColor: "red",
      },
      "&:hover": {
        outline: "none !important",
        borderColor: "red",
      },
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    multiValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    control: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };

  const value = selectContact
    ? {
        label: `${selectContact?.attributes?.name} - ${selectContact?.attributes?.emails[0]?.email}`,
        value: selectContact.id,
      }
    : null;

  const options =
    contactList && contactList.length > 0
      ? contactList.map(item => {
          return {
            value: item.id,
            label: `${item?.attributes?.name} - ${item?.attributes?.emails[0]?.email}`,
          };
        })
      : [];

  const searchOptions = async term => {
    try {
        if (currentOfficeId) {
            const data = {
              filters: {
                query: term
              }
            }
            const response = await post( `api/v1/contacts/filter?office_id=${currentOfficeId}`, data);
            const results = response.data.data;

            let options = [];
            if (results.length > 0) {
                options = results.map(option => {
                    return {
                        value: option.id,
                        label: `${option.attributes.name} <${option.attributes.emails[0].email}>`,
                    };
                });
                return options;

            } else {
                return [];
            }
        }
    } catch (e) {
        return [];
    }
  };

  const lookupOptions = inputValue => {
    return new Promise(resolve => {
        resolve(searchOptions(inputValue));
    });
  };

  return (
    <div>
      <div>
        <label className="label col-span-2">Contact</label>
        {/* <CreatableSelect
          styles={customStyles}
          options={options}
          value={value}
          placeholder="Find contact by name, email, or phone"
          onChange={handleChange}
          isDisabled={props.type === MESSAGE_TYPE_EDIT}
          onInputChange={setSearchStr}
          inputValue={searchStr}
          isClearable
        /> */}
        <AsyncCreatableSelect
            styles={customStyles}
            cacheOptions
            value={selected}
            name="contact-select"
            loadOptions={lookupOptions}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Find contact by name, email, or phone"
            onChange={handleChange}
            isDisabled={props.type === MESSAGE_TYPE_EDIT}
            onInputChange={setSearchStr}
            inputValue={searchStr}
            isClearable
        />
        <p className="text-xs text-red-500">{props.formError.contact}</p>
      </div>
      {formType === FORM_TYPE_CREATE && (
        <ContactForm
          handleContactList={handleContactList}
          handleSelectContact={handleSelectContact}
          handleFormType={setFormType}
        />
      )}
    </div>
  );
};

export default MessageContactForm;
