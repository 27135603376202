import React, { createContext, useEffect, useReducer } from "react";
import { post, deleteAPI } from "src/utils/axios";
import LoadingScreen from "src/components/LoadingScreen";
import toast from "react-hot-toast";

const isValidToken = (accessToken, expiry) => {
  if (!accessToken) return false;
  const currentTime = Date.now() / 1000;
  return parseInt(expiry) > currentTime;
};

const setSession = data => {
  if (data) {
    const { accessToken, expiry, client, uid } = data;
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("expiry", expiry);
    localStorage.setItem("client", client);
    localStorage.setItem("uid", uid);
  } else {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("expiry");
    localStorage.removeItem("client");
    localStorage.removeItem("uid");
  }
};

const setUserInfo = user => {
  if (user) {
    localStorage.setItem("user", user);
  } else {
    localStorage.removeItem("user");
  }
};

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INITIALISE": {
      const { isAuthenticated, user } = action.payload;
      return {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
      };
    }
    case "LOGIN": {
      const { user } = action.payload;

      return {
        ...state,
        isInitialized: true,
        isAuthenticated: true,
        user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    }
    case "REGISTER": {
      const { user } = action.payload;
      return {
        ...state,
        isAuthenticated: true,
        user,
      };
    }
    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async data => {
    try {
      const response = await post("/auth/sign_in", data);

      const accessToken = response.headers["access-token"];
      const expiry = response.headers["expiry"];
      const uid = response.headers["uid"];
      const client = response.headers["client"];

      setSession({ accessToken, expiry, uid, client });
      setUserInfo(JSON.stringify(response.data.data));

      dispatch({
        type: "LOGIN",
        payload: {
          isAuthenticated: true,
          user: response.data.data,
        },
      });
    } catch (error) {
      toast.error("Login failed. Please contact us.");
    }
  };

  const magicAuth = async data => {
    try {
      const response = await post(
        `auth/authenticate?email=${data.email}&sign_in_token=${data.magicCode}`
      );
      console.info(response);

      const accessToken = response.headers["access-token"];
      const expiry = response.headers["expiry"];
      const uid = response.headers["uid"];
      const client = response.headers["client"];

      setSession({ accessToken, expiry, uid, client });
      setUserInfo(JSON.stringify(response.data.data));

      dispatch({
        type: "LOGIN",
        payload: {
          isAuthenticated: true,
          user: response.data.data,
        },
      });
    } catch (error) {
      toast.error("Login failed. Please contact us.");
    }
  };

  const logout = async () => {
    try {
      await deleteAPI("/auth/sign_out");

      setSession(null);
      setUserInfo(null);
      localStorage.removeItem("currentOffice");
      localStorage.removeItem("user");
      localStorage.removeItem("uid");
      localStorage.removeItem("client");
      localStorage.removeItem("expiry");
      localStorage.removeItem("accessToken");

      dispatch({ type: "LOGOUT" });
    } catch (error) {
      toast.error("Unable to log you out. Please try again in a few minutes.");
    }
  };

  const register = async data => {
    try {
      const response = await post("/auth", data);
      const accessToken = response.headers["access-token"];
      const expiry = response.headers["expiry"];
      const uid = response.headers["uid"];
      const client = response.headers["client"];

      setSession({ accessToken, expiry, uid, client });
      setUserInfo(JSON.stringify(response.data.data));

      dispatch({
        type: "REGISTER",
        payload: {
          user: response.data.data,
        },
      });
    } catch (error) {
      toast.error(
        "Unable to sign up at the moment. Please try in a few minutes."
      );
    }
  };

  useEffect(() => {
    const initialize = () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");
        const expiry = window.localStorage.getItem("expiry");
        const uid = window.localStorage.getItem("uid");
        const client = window.localStorage.getItem("client");

        if (accessToken && isValidToken(accessToken, expiry)) {
          setSession({ accessToken, expiry, uid, client });
          const user = JSON.parse(window.localStorage.getItem("user"));

          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: true,
              user,
            },
          });
        } else {
          setSession(null);
          dispatch({
            type: "INITIALISE",
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.log(err);
        dispatch({
          type: "INITIALISE",
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  if (!state.isInitialized) {
    return <LoadingScreen />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
        register,
        magicAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
