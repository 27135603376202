import axios from "axios";

// eslint-disable-next-line no-undef
const baseURL = process.env["REACT_APP_BASE_URL"];

export const getAxios = () => {
  let axiosInstance;

  const accessToken = window.localStorage.getItem("accessToken");
  const uid = window.localStorage.getItem("uid");
  const client = window.localStorage.getItem("client");

  const headers =
    accessToken && uid && client
      ? {
          uid: uid,
          "access-token": accessToken,
          client: client,
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      : {
          Accept: "application/json",
        };

  axiosInstance = axios.create({
    headers,
    timeout: 30000,
  });

  axiosInstance.interceptors.response.use(
    function (response) {
      return Promise.resolve(response);
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export const get = uri => {
  return new Promise((resolve, reject) => {
    getAxios()
      .get(baseURL + uri)
      .then(res => {
        resolve(res);
      })
      .catch(err => {
        reject(err);
      });
  });
};

export const post = (uri, data) => {
  return new Promise((resolve, reject) => {
    getAxios()
      .post(baseURL + uri, data)
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const put = (uri, data) => {
  return new Promise((resolve, reject) => {
    getAxios()
      .put(baseURL + uri, data)
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export const deleteAPI = uri => {
  return new Promise((resolve, reject) => {
    getAxios()
      .delete(baseURL + uri)
      .then(res => {
        resolve(res);
      })
      .catch(error => {
        reject(error);
      });
  });
};
