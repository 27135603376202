import React from "react";

const ModalHeader = ({ title, onClose }) => {
  return (
    <div className="mb-4 pb-2 border-gray-200 border-b-1 text-xl font-bold">
      {title}
    </div>
  );
};

export default ModalHeader;
