import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import {
  BookOpenIcon,
  CheckCircleIcon,
  ClipboardIcon,
  CogIcon,
  HomeIcon,
  InboxIcon,
  LogoutIcon,
  NewspaperIcon,
  PhoneIcon,
  SparklesIcon,
  TemplateIcon,
  UserGroupIcon,
  UserIcon,
  SpeakerphoneIcon,
  BellIcon,
  ChartBarIcon,
} from "@heroicons/react/outline";
import { BuildingIcon } from "src/assets/icons";

const Menu = props => {
  const { title } = props;
  const history = useHistory();

  let menuIcon;
  let routeUrl = "";

  switch (title) {
    case "Home":
      menuIcon = <HomeIcon />;
      routeUrl = "/";
      break;
    case "Feed":
      menuIcon = <HomeIcon />;
      routeUrl = "/feed";
      break;
    case "Emails":
      menuIcon = <InboxIcon />;
      routeUrl = "/emails/inbox";
      break;
    case "Messages":
      menuIcon = <PhoneIcon />;
      routeUrl = "/messages";
      break;
    case "Tasks":
      menuIcon = <CheckCircleIcon />;
      routeUrl = "/tasks";
      break;
    case "Workflows":
      menuIcon = <SparklesIcon />;
      routeUrl = "/workflow";
      break;
    case "Templates":
      menuIcon = <TemplateIcon />;
      routeUrl = "/template";
      break;
    case "Posts":
      menuIcon = <SpeakerphoneIcon />;
      routeUrl = "/posts";
      break;
    case "People":
      menuIcon = <UserGroupIcon />;
      routeUrl = "/people";
      break;
    case "Bills":
      menuIcon = <NewspaperIcon />;
      routeUrl = "/data/bills";
      break;
    case "Topics":
      menuIcon = <ClipboardIcon />;
      routeUrl = "/data/topics";
      break;
    case "Subtopics":
      menuIcon = <BookOpenIcon />;
      routeUrl = "/data/issues";
      break;
    case "Contacts":
      menuIcon = <UserGroupIcon />;
      routeUrl = "/people";
      break;
    case "Forecasts":
      menuIcon = <ChartBarIcon />;
      routeUrl = "/forecasts";
      break;
    case "Discover":
      menuIcon = <BuildingIcon />;
      routeUrl = "/public_offices";
      break;
    case "Office":
      menuIcon = <CogIcon />;
      routeUrl = "/office-profile";
      break;
    case "Notifications":
      menuIcon = <BellIcon />;
      routeUrl = "/notifications";
      break;
    case "Account":
      menuIcon = <UserIcon />;
      routeUrl = "/account";
      break;
  }

  return (
    <div
      className="px-4 py-1 cursor-pointer hover:bg-purple-100 rounded-r-sm flex items-center space-x-1"
      onClick={() => history.push(routeUrl)}
    >
      <div className="h-4 w-4">{menuIcon}</div>
      <span>{title}</span>
    </div>
  );
};

export default Menu;
