import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LegislataButton from "src/components/LegislataButton";
import { deleteAPI, get, post, put } from "src/utils/axios";
import { useSelector } from "react-redux";
import { currentOfficeIdSelector } from "src/slices/office";
import TaskTopics from "../TaskTopics";
import TaskIssues from "../TaskIssues";
import TaskBills from "../TaskBills";
import TaskUsers from "../TaskUsers";
import { TagIcon } from "@heroicons/react/outline";
import { TrashIconOutline } from "src/assets/icons";
import toast from "react-hot-toast";

const FORM_TYPE_NEW = "FORM_TYPE_NEW";
const FORM_TYPE_EDIT = "FORM_TYPE_EDIT";

const TaskModal = props => {
  const task = props.data;
  const { onRefreshTasks, onClose } = props;

  const [formVal, setFormVal] = useState({
    subject: props.data?.attributes?.subject || "",
    body: props.data?.attributes?.body || "",
  });

  const [topics, setTopics] = useState([]);
  const [issues, setIssues] = useState([]);
  const [bills, setBills] = useState([]);
  const [assignees, setAssignees] = useState(
    props.data?.attributes?.assignees || []
  );
  const [startDate, setStartDate] = useState(
    props.data?.attributes?.due_date
      ? new Date(props.data?.attributes?.due_date)
      : new Date()
  );
  const [threadSubject, setThreadSubject] = useState("");

  useEffect(() => {
    if (props.type === FORM_TYPE_EDIT) {
      handleLoadTopics();
      handleLoadIssues();
      handleLoadBills();
    }

    if (props.data?.attributes?.source) {
      handleLoadEmailThread();
    }
  }, []);
  const currentOfficeId = useSelector(currentOfficeIdSelector);

  const handleLoadTopics = async () => {
    try {
      const result = await get(
        `api/v1/tasks/${props.data.id}/topics?office_id=${currentOfficeId}`
      );
      setTopics(result?.data?.data || []);
    } catch (error) {
      console.log("load topics", error);
    }
  };

  const handleLoadEmailThread = async () => {
    try {
      const result = await get(
        `api/v1/threads/${props.data.attributes.source.id}/emails`
      );
      setThreadSubject(result?.data?.data[0].attributes.subject);
    } catch (error) {
      console.log("load topics", error);
    }
  };

  const handleLoadIssues = async () => {
    try {
      const result = await get(
        `api/v1/tasks/${props.data.id}/issues?office_id=${currentOfficeId}`
      );
      setIssues(result?.data?.data || []);
    } catch (error) {
      console.log("load topics", error);
    }
  };

  const handleLoadBills = async () => {
    try {
      const result = await get(
        `api/v1/tasks/${props.data.id}/bills?office_id=${currentOfficeId}`
      );
      setBills(result?.data?.data || []);
    } catch (error) {
      console.log("load topics", error);
    }
  };

  const onCreateTask = async () => {
    const data = {
      task: {
        subject: formVal.subject,
        body: formVal.body,
        due_date: startDate,
        assignee_ids: assignees.map(item => item.id),
        topic_ids: topics.map(item => item.id),
        issue_ids: issues.map(item => item.id),
        bill_ids: bills.map(item => item.id),
      },
    };

    try {
      if (props.threadId) {
        const result = await post(
          `api/v1/threads/${props.threadId}/tasks?office_id=${currentOfficeId}`,
          data
        );
      } else {
        const result = await post(
          `api/v1/tasks?office_id=${currentOfficeId}`,
          data
        );
        console.log("task is created success", result);
      }

      const { onRefreshTasks, onClose } = props;
      if (onRefreshTasks) {
        onRefreshTasks();
      }
      onClose();
    } catch (error) {
      console.log("create error", error);
    }
  };

  const onFullUpdateTask = async () => {
    const data = {
      subject: formVal.subject,
      body: formVal.body,
      due_date: startDate,
      assignee_ids: assignees.map(item => item.id),
      topic_ids: topics.map(item => item.id),
      issue_ids: issues.map(item => item.id),
      bill_ids: bills.map(item => item.id),
    };
    try {
      const result = await put(
        `api/v1/tasks/${props.data.id}?office_id=${currentOfficeId}`,
        {
          task: data,
        }
      );

      if (onRefreshTasks) {
        onRefreshTasks();
      }
      onClose();

      console.log("task is updated", result);
    } catch (error) {
      console.log("task update error", error);
    }
  };

  const handleChange = evt => {
    setFormVal({
      ...formVal,
      [evt.target.name]: evt.target.value,
    });
  };

  const handleSubmit = evt => {
    evt.preventDefault();

    if (props.type === FORM_TYPE_NEW) {
      onCreateTask();
    } else {
      onFullUpdateTask();
    }
  };

  const handleTaskDeletion = async () => {
    try {
      await deleteAPI(`api/v1/tasks/${task.id}?office_id=${currentOfficeId}`);
      if (onRefreshTasks) {
        onRefreshTasks();
      }
      onClose();
    } catch (error) {
      toast.error("This task cannot be deleted.");
    }
  };

  const handleCloseModal = () => {
    const { onClose } = props;
    onClose();
  };

  return (
    <form style={{ minWidth: "600px" }}>
      <div className="pt-5 pb-6 px-7">
        <div className="flex justify-center text-2xl font-semibold text-gray-800 mb-3">
          {props.type === FORM_TYPE_NEW ? "Create New Task" : "Edit Task"}
        </div>
        {props.data?.attributes?.source && (
          <div className="mb-6">
            <div className="mb-1 text-sm font-semibold">
              <label htmlFor="inline-subject">Related to Email</label>
            </div>
            <a
              href={`emails/thread/${props.data.attributes.source.id}`}
              className="text-indigo-500 cursor-pointer"
            >
              Email {threadSubject}
            </a>
          </div>
        )}
        <div className="mb-6">
          <div className="ml-1 mb-1 text-sm font-semibold">
            <label htmlFor="inline-subject">Subject</label>
          </div>
          <div>
            <input
              className="form-input w-full"
              type="text"
              name="subject"
              value={formVal.subject}
              onChange={handleChange}
              placeholder="Give the task a title"
              required
            />
          </div>
        </div>
        <div className="mb-6">
          <div className="ml-1 mb-1 text-sm font-semibold">
            <label htmlFor="inline-body">Body</label>
          </div>
          <div>
            <textarea
              id="inline-body"
              className="form-input h-20 w-full"
              rows="6"
              value={formVal.body}
              name="body"
              onChange={handleChange}
              placeholder="Add details about the task"
            />
          </div>
        </div>
        <div className="flex justify-between mb-3">
          <div className="w-1/2 mr-1">
            <div className="ml-1 mb-1 text-sm font-semibold">
              <label htmlFor="inline-date">Due Date</label>
            </div>

            <div className="w-full">
              <DatePicker
                className="form-input input-clear"
                dateFormat="yyyy-MM-dd"
                minDate={startDate}
                selected={startDate}
                onChange={date => setStartDate(date)}
              />
            </div>
          </div>
          <div className="w-1/2 ml-1">
            <div className="ml-1 mb-1 text-sm font-semibold">
              <label htmlFor="inline-bill">Bill</label>
            </div>
            <div>
              <TaskBills data={bills} handleUpdateData={setBills} />
            </div>
          </div>
        </div>
        <div className="flex justify-between mb-12">
          <div className="w-1/2 mr-1">
            <div className="ml-1 mb-1 text-sm font-semibold">
              <label htmlFor="inline-topic">Topic</label>
            </div>
            <div>
              <TaskTopics data={topics} handleUpdateData={setTopics} />
            </div>
          </div>
          <div className="w-1/2 ml-1">
            <div className="ml-1 mb-1 text-sm font-semibold">
              <label htmlFor="inline-issue">Subtopics</label>
            </div>
            <div>
              <TaskIssues data={issues} handleUpdateData={setIssues} />
            </div>
          </div>
        </div>
        <div className="flex justify-center pt-2 pb-6 mb-12">
          <div className="w-3/5">
            <div className="ml-1 mb-1 text-sm font-semibold">
              <label htmlFor="inline-date">Assigned to</label>
            </div>
            <div>
              <TaskUsers data={assignees} handleUpdateData={setAssignees} />
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div>
            <button
              type="button"
              onClick={() => {
                if (
                  window.confirm(
                    "Please confirm that you want to delete this task. Once deleted, the task cannot be recovered."
                  )
                )
                  handleTaskDeletion();
              }}
            >
              <div className="flex items-center space-x-1 text-sm text-red-700 hover:text-red-800">
                <TrashIconOutline />
                <div>Delete Task</div>
              </div>
            </button>
          </div>
          <div className="flex items-center space-x-4">
            <LegislataButton
              value="Cancel"
              type="outline"
              customStyle="text-blue-700 border-blue-500 hover:bg-blue-500"
              textSize="text-sm"
              styles="text-base bg-gray-300 rounded py-1 px-4 flex items-center justify-between cursor-pointer"
              onClick={handleCloseModal}
            ></LegislataButton>
            <LegislataButton
              value="Save Task"
              type="primary"
              onClick={handleSubmit}
            ></LegislataButton>
          </div>
        </div>
      </div>
    </form>
  );
};

export default TaskModal;
