import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Select from "react-select";
import { get } from "src/utils/axios";

const perPage = 1000;

const TaskBills = props => {
  const office = useSelector(state => state.office);
  const [billList, setBillList] = useState([]);

  useEffect(() => {
    if (office) {
      handleLoadBills();
    }
  }, [office]);

  const handleLoadBills = async () => {
    const officeId = office.currentOffice.id;
    try {
      const result = await get(
        `api/v1/bills?office_id=${officeId}&per_page=${perPage}&page=1`
      );
      setBillList(result.data.data);
    } catch (error) {
      console.log("topics --- ", error);
    }
  };

  const handleChangeOption = newValue => {
    const { handleUpdateData } = props;
    const bills = billList.filter(item => {
      const optionItem = newValue.find(
        optionItem => optionItem.value === item.id
      );
      if (optionItem) {
        return true;
      } else {
        return false;
      }
    });
    handleUpdateData(bills);
  };

  const options = billList.map(item => {
    return {
      value: item.id,
      label: item.attributes.name,
    };
  });
  const value = props.data
    ? props.data.map(item => {
        return {
          value: item.id.toString(),
          label: item.attributes.name,
        };
      })
    : [];
  const customStyles = {
    option: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    input: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };
  return (
    <Select
      isMulti
      value={value}
      styles={customStyles}
      name="bills"
      options={options}
      onChange={handleChangeOption}
      className="basic-multi-select"
      classNamePrefix="select"
      isClearable={true}
    />
  );
};

export default TaskBills;
