import React from "react";
import { parseISO, formatDistanceToNow } from "date-fns";

const PostUpdatedTime = ({ timestamp, className }) => {
  let timeAgo = "";
  if (timestamp) {
    const date = parseISO(timestamp);
    const timePeriod = formatDistanceToNow(date);
    timeAgo = `${timePeriod} ago`;
  }

  return <p className={className}>{timeAgo}</p>;
};

export default PostUpdatedTime;
