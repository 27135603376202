import { createSlice, createSelector } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { get } from "src/utils/axios";

const initialState = {
  tasks: [],
};

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTasks(state, action) {
      state.tasks = action.payload;
    },
  },
});

export const fetchTasks = officeId => async dispatch => {
  try {
    if (officeId) {
      const res = await get(`api/v1/tasks?office_id=${officeId}`);
      dispatch(taskSlice.actions.setTasks(res.data.data));
    }
  } catch (error) {
    toast.error("Something broke, please retry your request…");
  }
};

export const tasksSelector = state => state.task.tasks;

export const { setTasks } = taskSlice.actions;

export const reducer = taskSlice.reducer;
