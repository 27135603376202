import React, { useEffect, useState } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { get, post } from "src/utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { fetchIssues, fetchTopics } from "src/slices/office";

const ContactTopics = ({
  optionList,
  valueList = [],
  source = "primary",
  handleUpdateValue,
  type,
}) => {
  const dispatch = useDispatch();
  const office = useSelector(state => state.office);
  const { thread_id } = useParams();
  const [values, setValues] = useState(
    valueList &&
      valueList.map(item => {
        return {
          value: item.id,
          label: item.attributes.name,
        };
      })
  );

  useEffect(() => {
    var initOptions = [];
    valueList &&
      valueList.map(item => {
        var option = {
          value: item.id,
          label: item.attributes.name,
        };
        initOptions.push(option);
      });

    setValues(initOptions);
  }, [valueList]);

  const handleChange = (newValue, actionMeta) => {
    if (source === "primary") {
      switch (actionMeta.action) {
        case "create-option":
          handleCreateOption(actionMeta.option.value);
          break;
        case "select-option":
          handleSelectOption(newValue);
          setValues(newValue);
          break;
        case "remove-value":
          handleSelectOption(newValue);
          setValues(newValue);
          break;
        default:
          break;
      }
    } else if (source === "no-api") {
      setValues(newValue);
      if (actionMeta.action === "create-option") {
        handleCreateOption(actionMeta.option.value);
      } else {
        handleUpdateValue(newValue);
      }
    }
  };

  const handleCreateOption = async value => {
    const officeId = office.currentOffice.id;

    try {
      let result = await post(`api/v1/${type}s?office_id=${officeId}`, {
        name: value,
      });
      if (type === "issue") {
        dispatch(fetchIssues(officeId));
      } else if (type === "topic") {
        dispatch(fetchTopics(officeId));
      }
      const item = result.data.data;
      const ids = valueList ? valueList.map(item => item.id) : [];
      ids.push(item.id);

      if (source === "no-api") {
        handleUpdateValue(
          ids.map(id => {
            return {
              value: id,
              label: "",
            };
          })
        );
        return;
      }

      await post(`api/v1/threads/${thread_id}/${type}s?office_id=${officeId}`, {
        [`${type}_ids`]: ids,
      });

      setValues(
        values.concat([
          {
            value: item.id,
            label: item.attributes.name,
          },
        ])
      );

      handleUpdateValue(
        ids.map(id => {
          return {
            value: id,
            label: "",
          };
        })
      );
    } catch (error) {
      console.log("creating option error", error);
      toast("Something broke, please retry your request…");
    }
  };

  const handleSelectOption = async valueArr => {
    const ids = valueArr.map(item => item.value);
    const officeId = office.currentOffice.id;

    try {
      await post(`api/v1/threads/${thread_id}/${type}s?office_id=${officeId}`, {
        [`${type}_ids`]: ids,
      });
    } catch (error) {
      console.log("handle select error", error);
      toast("Something broke, please retry your request…");
    }
  };

  const options = optionList
    ? optionList.map(item => {
        return {
          value: item.id,
          label: item.attributes.name,
        };
      })
    : [];

  // const value = valueList
  //   ? valueList.map(item => {
  //       return {
  //         value: item.id,
  //         label: item.attributes.name,
  //       };
  //     })
  //   : [];

  const customStyles = {
    option: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    input: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    multiValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };

  const searchTopicOrIssue = async term => {
    try {
      const response = await get(
        `api/v1/${type}s?query=${term}&office_id=${office.currentOffice.id}`
      );

      let results = [];
      if (response.data.data.length > 0) {
        results = response.data.data.map(elem => {
          return {
            value: elem.id,
            label: elem.attributes.name,
          };
        });

        return results;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  };

  const lookupTopicsOrIssues = inputValue => {
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(searchTopicOrIssue(inputValue));
      }, 250);
    });
  };

  let placeholder;

  switch (type) {
    case "topic":
      placeholder = "Select topic(s)";
      break;

    case "issue":
      placeholder = "Select subtopic(s)";
      break;
  }

  return (
    <AsyncCreatableSelect
      isMulti
      styles={customStyles}
      defaultOptions={options}
      value={values}
      onChange={handleChange}
      placeholder={placeholder}
      loadOptions={lookupTopicsOrIssues}
    />
  );
};

export default ContactTopics;
