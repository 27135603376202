import React, { useState, useEffect, useRef } from "react";
import { post } from "src/utils/axios";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { SpeakerphoneIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

const GlobalSearch = () => {
  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [debounceTimer, setDebounceTimer] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to control dropdown visibility
  const dropdownRef = useRef(null); // Ref to the dropdown element

  // Debounce function to delay API calls
  const debounce = (func, delay) => {
    return (...args) => {
      clearTimeout(debounceTimer);
      setDebounceTimer(setTimeout(() => func.apply(this, args), delay));
    };
  };

  // Function to fetch search results
  const fetchSearchResults = async () => {
    try {
      const data = {
        filters: {
          query: searchTerm,
          global: 1,
        },
      };
      const response = await post(`api/v1/posts/filter`, data);
      setSearchResults(response.data.data.slice(0, 10)); // Limit to first 5 results
      if (response.data.data.length > 0) {
        setIsDropdownOpen(true);
      }
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  // Effect to call fetchSearchResults after user stops typing
  useEffect(() => {
    if (searchTerm.length >= 3) {
      debounce(fetchSearchResults, 300)();
    } else {
      setSearchResults([]);
    }
  }, [searchTerm]);

  // Function to close the dropdown when clicking outside
  const closeDropdown = e => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownOpen(false);
    }
  };

  // Add event listener to close dropdown on outside click
  useEffect(() => {
    document.addEventListener("click", closeDropdown);
    return () => document.removeEventListener("click", closeDropdown);
  }, []);

  const clearOnClick = e => {
    e.stopPropagation();
    setIsDropdownOpen(false);
    setSearchTerm("");
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      setIsDropdownOpen(false);
      history.push(`/posts/search?term=${searchTerm}`);
    }
  };

  return (
    <div className="relative w-full">
      <div className="relative flex w-ful">
        <input
          className="border border-gray-300 h-10 rounded-sm py-1 px-4 w-full"
          type="text"
          placeholder="Search posts across your offices"
          value={searchTerm}
          onChange={e => setSearchTerm(e.target.value)}
          onKeyDown={handleKeyDown}
          style={{ paddingRight: "30px" }}
        />
        {/* <div className="absolute right-2 top-1/2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="w-6 h-6"
          >
            <path
              fill-rule="evenodd"
              d="M10.5 3.75a6.75 6.75 0 1 0 0 13.5 6.75 6.75 0 0 0 0-13.5ZM2.25 10.5a8.25 8.25 0 1 1 14.59 5.28l4.69 4.69a.75.75 0 1 1-1.06 1.06l-4.69-4.69A8.25 8.25 0 0 1 2.25 10.5Z"
              clip-rule="evenodd"
            />
          </svg>
        </div> */}
      </div>
      {isDropdownOpen && searchResults.length > 0 && (
        <div className="absolute left-0 top-10 w-96">
          <ul
            ref={dropdownRef}
            className="bg-white border border-gray-200 pt-2"
          >
            {searchResults.map((result, index) => (
              <li
                onClick={e => clearOnClick(e)}
                className="py-1 px-4 cursor-pointer hover:bg-gray-100"
                key={index}
              >
                <Link
                  className="font-medium text-sm no-underline text-black"
                  to={`/posts/${result.id}/detail?oid=${result.attributes.office_id}`}
                >
                  <div className="flex items-center space-x-3">
                    <div className="h-5 w-5 text-gray-600 font-light">
                      <SpeakerphoneIcon />
                    </div>
                    <div>
                      {result.attributes.title}
                      <div
                        className="text-sm text-gray-500"
                        dangerouslySetInnerHTML={{
                          __html: result.attributes.description
                            .substring(0, 50)
                            .replace(/(<([^>]+)>)/gi, ""),
                        }}
                      />
                    </div>
                  </div>
                </Link>
              </li>
            ))}
            <li className="mt-2 text-center border-t py-2 px-4 cursor-pointer hover:bg-gray-100">
              <a
                className="no-underline font-medium"
                href={`/posts/search?term=${searchTerm}`}
              >
                Show all results
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default GlobalSearch;
