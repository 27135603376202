import React, { useState, useEffect, useCallback } from "react";

import { CloseIcon, AttachIcon } from "../../assets/icons";

import LegislataButton from "../../components/LegislataButton";
import { ContactTemplates } from "../InboxDetail";
import toast from "react-hot-toast";
import "react-responsive-modal/styles.css";
import RichTextEditor from "src/components/RichTextEditor";
import { post } from "src/utils/axios";
import { isEmpty } from "lodash";
import { TemplateModal } from "../Template/components";
import Modal from "react-responsive-modal";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/outline";
import { random } from "lodash-es";
import { useSelector } from "react-redux";
import { currentOfficeSelector } from "src/slices/office";

const ComposeOrReply = ({
  replyToId = null,
  toRecipients = [],
  ccRecipients = [],
  bccRecipients = [],
  useSubject = "",
  onSending = null,
  onSend = null,
}) => {
  const [fileIds, setFileIds] = useState([]); // [[ filename, uploaded_file_id ], ..] uploaded_file_id = returned by backend
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [file, selectFile] = useState(null);
  const [emailAddTextfield, selectEmailAddTextfield] = useState(null);
  const [subjectTextfield, selectSubjectTextfield] = useState(null);
  const [isClearedEditor, setIsClearedEditor] = useState(false);
  const [subject, setSubject] = useState(useSubject);
  const [typingToEmail, setTypingToEmail] = useState("");
  const [typingCCEmail, setTypingCCEmail] = useState("");
  const [typingBCCEmail, setTypingBCCEmail] = useState("");
  const [toEmails, setToEmails] = useState(toRecipients);
  const [ccEmails, setCCEmails] = useState(ccRecipients);
  const [bccEmails, setBCCEmails] = useState(bccRecipients);
  const [showCC, setShowCC] = useState(false);
  const [showBCC, setShowBCC] = useState(false);
  const [body, setEmailBody] = useState("");
  const [sending, setSending] = useState(false);
  const [templateModal, setTemplateModal] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [emailContent, setEmailContent] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [okToSend, setOkToSend] = useState(false);

  const currentOffice = useSelector(currentOfficeSelector);

  useEffect(async () => {
    if (okToSend) {
      if (onSending) {
        onSending();
      }

      const data = {
        subject,
        body,
        to: toEmails,
        cc: ccEmails,
        bcc: bccEmails,
      };

      if (fileIds.length > 0) {
        data["file_ids"] = fileIds.map(fileInfo => fileInfo[1]);
      }

      try {
        if (replyToId) {
          await post(
            `api/v1/emails/${replyToId}/reply?office_id=${currentOffice.id}`,
            data
          );
          toast.success("Reply sent");
          setCCEmails([]);
          setBCCEmails([]);
          setEmailBody("");
        } else {
          await post(`api/v1/emails?office_id=${currentOffice.id}`, data);
          toast.success("Email sent");
          setCCEmails([]);
          setBCCEmails([]);
          setEmailBody("");
        }

        if (onSend) {
          onSend();
        }
      } catch (error) {
        console.error(error);
        toast.error(
          "Unable to send email at this time. Please try again in a few minutes."
        );
      } finally {
        setOkToSend(false);
        setSending(false);
      }
    }
  }, [okToSend]);

  useEffect(() => {
    if (sending) {
      if (toEmails.length === 0 && typingToEmail !== "") {
        if (validateEmail(typingToEmail)) {
          setToEmails([{ email: typingToEmail, name: "" }]);
        } else {
          toast.error("Please specify a valid email for the recipient");
          setSending(false);
          return;
        }
      }

      if (ccEmails.length === 0 && typingCCEmail !== "") {
        if (validateEmail(typingCCEmail)) {
          setCCEmails([{ email: typingCCEmail, name: "" }]);
        } else {
          toast.error(
            "The email in cc was not recognized. Please enter a valid email."
          );
          setSending(false);
          return;
        }
      }

      if (bccEmails.length === 0 && typingBCCEmail !== "") {
        if (validateEmail(typingBCCEmail)) {
          setBCCEmails([{ email: typingBCCEmail, name: "" }]);
        } else {
          toast.error(
            "The email in bcc was not recognized. Please enter a valid email."
          );
          setSending(false);
          return;
        }
      }

      if (toEmails.length === 0) {
        toast.error("There must be at least one recipient");
        setSending(false);
        return;
      }

      if (isEmpty(subject)) {
        const result = confirm(
          "Are you sure you want to send without a subject?"
        );
        if (!result) {
          setSending(false);
          return;
        }
      }

      if (isEmpty(body)) {
        const result = confirm("Are you sure you want to send an empty email?");
        if (!result) {
          setSending(false);
          return;
        }
      }

      setOkToSend(true);
    }
  }, [sending]);

  useEffect(() => {
    if (selectedFile) {
      const file_ids = fileIds;
      file_ids.unshift([selectedFile.name, null]);
      setFileIds(file_ids);
      persistFile(selectedFile);
    }
  }, [selectedFile]);

  const onSetIsClearedEditor = () => {
    if (body !== "" && body !== "<p><br></p>") {
      const result = confirm(
        "Discarding will clear the email message. Do you wish to proceed?"
      );

      if (result) {
        setIsClearedEditor(true);
      }
    }
  };

  const onAddFile = async () => {
    await file.click();
  };

  const removeToEmail = email => {
    const emails = toEmails.filter(toEmail => email !== toEmail.email);
    setToEmails(emails);
  };

  const removeCCEmail = email => {
    const emails = ccEmails.filter(toEmail => email !== toEmail.email);
    setCCEmails(emails);
  };

  const removeBCCEmail = email => {
    const emails = bccEmails.filter(toEmail => email !== toEmail.email);
    setBCCEmails(emails);
  };

  const addToEmails = (e, email) => {
    if (e.key === "Enter" || e.key === ",") {
      if (!validateEmail(email)) setTypingToEmail("");
      else {
        setToEmails(toEmails.concat([{ email: email, name: "" }]));
        setTypingToEmail("");
      }
    }
  };

  const addCCEmails = (e, email) => {
    if (e.key == "Enter") {
      if (!validateEmail(email)) setTypingCCEmail("");
      else {
        setCCEmails(ccEmails.concat([{ email: email, name: "" }]));
        setTypingCCEmail("");
      }
    }
  };

  const addBCCEmails = (e, email) => {
    if (e.key == "Enter") {
      if (!validateEmail(email)) setTypingBCCEmail("");
      else {
        setBCCEmails(bccEmails.concat([{ email: email, name: "" }]));
        setTypingBCCEmail("");
      }
    }
  };

  const validateEmail = email => {
    var mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
    if (email.match(mailformat)) {
      const existEmail = toEmails.filter(email => email == email.email);
      return existEmail.length == 0 ? true : false;
    }
    return false;
  };

  const persistFile = async fileObj => {
    const formData = new FormData();
    formData.append("file", fileObj);
    try {
      const response = await post(
        `api/v1/files?office_id=${currentOffice.id}`,
        formData
      );
      const file_ids = fileIds;
      file_ids[0][1] = response.data.id;
      setFileIds(file_ids);
      setSelectedFile(null);
      setIsFilePicked(false);
    } catch (e) {
      toast.error("File upload failed");
      const file_ids = fileIds;
      file_ids.shift();
      setFileIds(file_ids);
      setSelectedFile(null);
      setIsFilePicked(false);
    }
  };

  const uploadFile = event => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSaveTemplate = () => {
    setTemplateModal(true);
    setSelectedTemplate({
      attributes: {
        subject,
        body,
      },
    });
  };

  const handleApplyTemplate = template => {
    setSubject(template.attributes.subject);
    setEmailContent(template.attributes.body);
    setEmailBody(template.attributes.body);
  };

  const handleCloseModal = () => {
    setTemplateModal(false);
  };

  const renderTemplate = () => {
    if (templateModal) {
      return (
        <Modal
          open={true}
          onClose={handleCloseModal}
          closeOnOverlayClick={true}
        >
          <TemplateModal
            data={selectedTemplate}
            type="MODAL_TYPE_NEW"
            handleAddTemplate={() => console.log("save template")}
            handleUpdateTemplate={() => console.log("save template")}
            handleCloseModal={handleCloseModal}
          />
        </Modal>
      );
    }
  };

  const renderReplyRecipients = () => {
    return (
      <div
        className="w-full space-y-1"
        // onClick={() => {
        //   emailAddTextfield.focus();
        // }}
      >
        <div className="flex justify-between">
          <div className="text-sm">
            <span className="text-gray-600">From:</span> <span>You</span>
          </div>
          <button
            className={`text-sm ${showCC ? "hidden" : ""}`}
            onClick={() => setShowCC(!showCC)}
          >
            Show cc/bcc
          </button>
        </div>
        <div className="w-full flex items-center space-x-2">
          <div className="form-input text-field w-full flex items-center space-x-1">
            {toEmails.map(data => (
              <div key={data.email}>
                <div
                  value={data.email}
                  className="border-1 border-gray-200 bg-white p-1 rounded-md flex items-baseline space-x-1"
                >
                  <span className="text-sm">{data.email}</span>
                  <button
                    onClick={() => {
                      removeToEmail(data.email);
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>
              </div>
            ))}
            <input
              className="appearance-none w-full form-input text-field text-sm focus:outline-none focus:border-0 focus:ring-0 focus:border-transparent"
              type="email"
              value={typingToEmail}
              placeholder="To"
              onChange={e => {
                setTypingToEmail(e.target.value);
              }}
              ref={email => {
                selectEmailAddTextfield(email);
              }}
              onKeyPress={e => addToEmails(e, e.target.value)}
            />
          </div>
        </div>

        {showCC && (
          <>
            <div className="w-full flex items-center space-x-2">
              <div className="form-input text-field w-full flex items-center space-x-1">
                {ccEmails.map(data => (
                  <div key={data.email}>
                    <div
                      value={data.email}
                      className="border-1 border-gray-200 bg-white p-1 rounded-md flex items-baseline space-x-1"
                    >
                      <span className="text-sm">{data.email}</span>
                      <button
                        onClick={() => {
                          removeCCEmail(data.email);
                        }}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                ))}
                <input
                  className="appearance-none w-full form-input text-field text-sm focus:outline-none focus:border-0 focus:ring-0 focus:border-transparent"
                  type="email"
                  placeholder="Cc"
                  value={typingCCEmail}
                  onChange={e => {
                    setTypingCCEmail(e.target.value);
                  }}
                  onKeyPress={e => addCCEmails(e, e.target.value)}
                />
              </div>
            </div>

            <div className="w-full flex items-center space-x-2">
              <div className="form-input text-field w-full flex items-center space-x-1">
                {bccEmails.map(data => (
                  <div key={data.email}>
                    <div
                      value={data.email}
                      className="border-1 border-gray-200 bg-white p-1 rounded-md flex items-baseline space-x-1"
                    >
                      <span className="text-sm">{data.email}</span>
                      <button
                        onClick={() => {
                          removeBCCEmail(data.email);
                        }}
                      >
                        <CloseIcon />
                      </button>
                    </div>
                  </div>
                ))}
                <input
                  className="appearance-none w-full form-input text-field text-sm focus:outline-none focus:border-0 focus:ring-0 focus:border-transparent"
                  type="email"
                  placeholder="Bcc"
                  value={typingBCCEmail}
                  onChange={e => {
                    setTypingBCCEmail(e.target.value);
                  }}
                  onKeyPress={e => addBCCEmails(e, e.target.value)}
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const renderSubject = () => {
    return isEmpty(useSubject) ? (
      <div
        className="w-full flex items-center space-x-2"
        // onClick={() => {
        //   subjectTextfield.focus();
        // }}
      >
        <div className="form-input text-field w-full flex items-center space-x-1">
          <input
            className="appearance-none w-full form-input text-field text-sm focus:outline-none focus:border-0 focus:ring-0 focus:border-transparent"
            type="email"
            value={subject}
            onChange={e => {
              setSubject(e.target.value);
            }}
            placeholder="Subject"
            // ref={email => {
            //   selectSubjectTextfield(email);
            // }}
            // onKeyPress={e => addBCCEmails(e, e.target.value)}
          />
        </div>
      </div>
    ) : (
      <div className="text-sm space-x-1">
        <span className="text-gray-600">Subject:</span>
        <span>{subject}</span>
      </div>
    );
  };

  return (
    <div className="relative w-full h-full space-y-1">
      <div className="flex justify-between space-x-8">
        {renderReplyRecipients()}
      </div>

      <div className="w-full flex justify-between items-center space-x-2">
        {renderSubject()}
        <div className="flex items-center">
          <div style={{ width: "122px" }}>
            <ContactTemplates
              placeholder="Templates"
              handleApplyTemplate={handleApplyTemplate}
            />
          </div>
        </div>
      </div>

      <RichTextEditor
        body={body}
        isClearedEditor={isClearedEditor}
        setIsClearedEditor={setIsClearedEditor}
        setEmailBody={body => setEmailBody(body)}
      />

      <div>
        {fileIds.length > 0 && (
          <div className="text-sm my-2">
            {fileIds.map((fileInfo, index) => {
              return (
                <div
                  key={index}
                  className="flex flex-row items-center space-x-8"
                >
                  <span>{fileInfo[0]}</span>
                  <span>
                    {fileInfo[1] ? (
                      <CheckCircleIcon className="w-4 font-semibold text-green-600" />
                    ) : (
                      "Uploading..."
                    )}
                  </span>
                </div>
              );
            })}
          </div>
        )}
      </div>
      {/* <Editor
              content={emailContent}
              isClearedEditor={isClearedEditor}
              setIsClearedEditor={setIsClearedEditor}
              setEmailBody={setEmailBody}
            /> */}
      <div className="flex justify-between" style={{ width: "inherit" }}>
        <div className="flex items-center space-x-3">
          <LegislataButton
            value={sending ? "Sending..." : "Send"}
            disabled={sending || isFilePicked}
            styles=""
            type="primary"
            onClick={() => setSending(true)}
          ></LegislataButton>

          <input
            type="file"
            disabled={!!selectedFile}
            name="file"
            className=""
            ref={email => {
              selectFile(email);
            }}
            onChange={uploadFile}
          />
          {/* <LegislataButton value="Attach" type="outline" onClick={onAddFile}>
            <AttachIcon />
          </LegislataButton> */}
        </div>
        <div>
          <button
            className="whitespace-nowrap text-sm border p-2 border-md"
            onClick={handleSaveTemplate}
          >
            Save Email as Template
          </button>
        </div>
        {/* temporarily hidden -- is it really needed? */}
        {/* <div>
          <LegislataButton
            value="Discard"
            type="outline"
            borderColor="border-gray-200"
            bgColor="bg-gray-100"
            styles="mr-2"
            onClick={onSetIsClearedEditor}
          ></LegislataButton>
        </div> */}
      </div>

      {renderTemplate()}
    </div>
  );
};

export default ComposeOrReply;
