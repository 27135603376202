import React, { useState, useEffect, useRef } from "react";
import useAuth from "src/hooks/useAuth";
import { MESSAGE_TYPE_NEW } from "src/assets/consts/message";
import { FORM_TYPE_NEW } from "src/utils/constants";
import NewMessage from "src/views/Messages/components/NewMessage";
import { TaskModal } from "src/views/Tasks/components";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchTasks } from "src/slices/task";
import { currentOfficeIdSelector } from "src/slices/office";
import ComposeNewEmail from "src/views/Email/New";
import { useHistory } from "react-router-dom";
import ContactForm from "src/views/Contacts/components/ContactForm";
import ModalHeader from "./ModalHeader";

const MENU_OPTIONS_CONNECT_EMAIL = [
  { title: "Message", identifier: "message" },
  { title: "Task", identifier: "task" },
  { title: "Contact", identifier: "contact" },
  { title: "Email", identifier: "email" },
  { title: "Post", identifier: "post" },
];

const MENU_OPTIONS_NOCONNECT_EMAIL = [
  { title: "Task", identifier: "task" },
  { title: "Contact", identifier: "contact" },
  { title: "Post", identifier: "post" },
];

const MENU_OPTIONS_TIER_FREE = [{ title: "Post", identifier: "post" }];

const GlobalAddDropdown = props => {
  const { onClick } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useAuth();

  const [isOpen, setIsOpen] = useState(false);
  const [showNewMessage, setShowNewMessage] = useState(false);
  const [showNewTask, setShowNewTask] = useState(false);
  const [showNewContact, setShowNewContact] = useState(false);
  const [showNewEmail, setShowNewEmail] = useState(false);
  const dropDownRef = useRef(null);

  const currentOfficeId = useSelector(currentOfficeIdSelector);
  const connectEmail = useSelector(
    state => state.office.currentOffice.attributes.connect_email
  );
  const officeTier = useSelector(
    state => state.office.currentOffice.attributes.tier
  );

  let MENU_OPTIONS = MENU_OPTIONS_CONNECT_EMAIL;
  let optionStyle = "-bottom-44";
  if (!connectEmail) {
    MENU_OPTIONS = MENU_OPTIONS_NOCONNECT_EMAIL;
    optionStyle = "-bottom-28";
  }

  if (!officeTier || officeTier === "free") {
    MENU_OPTIONS = MENU_OPTIONS_TIER_FREE;
    optionStyle = "-bottom-10";
  }

  // useEffect(() => {
  //   document.addEventListener("mousedown", event => {
  //     if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
  //       setIsOpen(false);
  //       setShowNewMessage(false);
  //     }
  //   });
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("keyup", event => {
  //     if (event.code === "Escape") {
  //       setIsOpen(false);
  //       setShowNewMessage(false);
  //     }
  //   });
  // }, []);

  const closeHandler = () => {
    setIsOpen(false);
    setShowNewMessage(false);
    setShowNewTask(false);
    setShowNewContact(false);
    setShowNewEmail(false);
  };

  const openDialog = type => {
    switch (type) {
      case "message":
        setIsOpen(false);
        setShowNewMessage(true);
        break;
      case "task":
        setIsOpen(false);
        setShowNewTask(true);
        break;
      case "contact":
        setIsOpen(false);
        setShowNewContact(true);
        break;
      case "email":
        setIsOpen(false);
        setShowNewEmail(true);
        break;
      case "post":
        history.push("/posts/new");
        break;
    }
  };

  const handleMessageUpdate = () => {};

  const renderNewMessageModal = () => {
    return (
      <NewMessage
        onClose={closeHandler}
        type={MESSAGE_TYPE_NEW}
        data={null}
        handleMessageUpdate={handleMessageUpdate}
      />
    );
  };

  const renderNewTaskModal = () => {
    return (
      <Modal open={true} onClose={closeHandler} closeOnOverlayClick={true}>
        <TaskModal
          type={FORM_TYPE_NEW}
          data={null}
          onClose={closeHandler}
          onRefreshTasks={() => dispatch(fetchTasks(currentOfficeId))}
        />
      </Modal>
    );
  };

  const redirectToNewContact = () => {
    return (
      <Modal open={true} onClose={closeHandler} closeOnOverlayClick={true}>
        <ModalHeader title="Add New Contact" />
        <ContactForm onClose={closeHandler} />
      </Modal>
    );
  };

  const renderNewEmailContainer = () => {
    return <ComposeNewEmail onClose={closeHandler} key={Math.random()} />;
  };

  const menu = () => {
    return (
      <div
        className={`origin-top-right absolute right-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none ${optionStyle}`}
        role="menu"
        aria-orientation="vertical"
        aria-labelledby="menu-button"
        tabIndex="-1"
      >
        <div className="py-1" role="none">
          {MENU_OPTIONS.map(option => (
            <span
              key={option.identifier}
              onClick={() => openDialog(option.identifier)}
              className="w-full text-left text-gray-700 block px-4 py-2 text-sm hover:bg-blue-50 hover:text-blue-800"
              role="menuitem"
              tabIndex="-1"
              id="menu-item-0"
            >
              {option.title}
            </span>
          ))}
        </div>
      </div>
    );
  };

  const onMousEnterBtn = () => {
    if (user) setIsOpen(true);
  };
  const onMousLeaveBtn = () => {
    if (user) setIsOpen(false);
  };
  const onClickBtn = () => {
    if (!user) onClick();
  };

  return (
    <div ref={dropDownRef} className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className="inline-flex justify-start items-center space-x-1 w-full rounded-md border border-gray-300 shadow-sm px-2 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500"
          id="menu-button"
          aria-expanded="true"
          aria-haspopup="true"
          onMouseEnter={onMousEnterBtn}
          onMouseLeave={onMousLeaveBtn}
          onClick={onClickBtn}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
          <span>New</span>
          {isOpen && menu()}
        </button>
      </div>
      {showNewMessage && renderNewMessageModal()}
      {showNewTask && renderNewTaskModal()}
      {showNewContact && redirectToNewContact()}
      {showNewEmail && renderNewEmailContainer()}
    </div>
  );
};

export default GlobalAddDropdown;
