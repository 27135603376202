import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { get } from "src/utils/axios";
import Select from "react-select";

const ContactTemplates = ({ placeholder = "", handleApplyTemplate }) => {
  const [templateData, setTemplateData] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const office = useSelector(state => state.office);
  useEffect(() => {
    if (office?.currentOffice?.id) {
      handleLoadTemplates();
    }
  }, [office]);

  const handleLoadTemplates = async () => {
    try {
      let result = await get(
        `api/v1/email_templates?office_id=${office.currentOffice.id}`
      );

      setTemplateData(result?.data?.data);
    } catch (error) {
      console.log("messages error", error);
    }
  };

  const handleChangeOption = newVal => {
    setSelectedTemplate(newVal);
    const template = templateData.find(item => item.id === newVal.value);
    handleApplyTemplate(template);
  };

  const customStyles = {
    option: provided => ({
      ...provided,
      fontSize: "0.875rem",
      innerWidth: "200px",
    }),
    input: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };

  const options = templateData.map(item => ({
    value: item.id,
    label: item.attributes.name,
  }));

  return (
    <Select
      styles={customStyles}
      value={selectedTemplate}
      name="templates"
      options={options}
      onChange={handleChangeOption}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder={placeholder === "" ? "Select" : placeholder}
    />
  );
};

export default ContactTemplates;
