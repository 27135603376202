import React, { useCallback, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { useSelector, useDispatch } from "react-redux";
import { get, post } from "src/utils/axios";
import { actions as workflowActions } from "src/slices/workflow";
import { debounce, forEach } from "lodash";

const mergeArray = (arrOne, arrTwo) => {
  const result = [...arrOne];
  arrTwo.forEach(item => {
    const findItem = arrOne.find(itemOne => item.id === itemOne.id);
    if (!findItem) {
      result.push(item);
    }
  });

  return result;
};

const perPage = 1000;

const TaskTopics = props => {
  const dispatch = useDispatch();
  const office = useSelector(state => state.office);
  const [loading, setLoading] = useState(false);
  const [topicList, setTopicList] = useState([]);

  //   useEffect(() => {
  //     if (data.forward_email || data.forward_email !== "") {
  //       handleQueryContact(data.forward_email);
  //     }
  //   }, []);

  useEffect(() => {
    if (office) {
      handleLoadTopics();
    }
  }, [office]);

  const handleLoadTopics = async () => {
    const officeId = office.currentOffice.id;
    try {
      const result = await get(
        `api/v1/topics?office_id=${officeId}&per_page=${perPage}&page=1`
      );
      setTopicList(result.data.data);
    } catch (error) {
      console.log("topics --- ", error);
    }
  };

  const customStyles = {
    option: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    input: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    multiValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };

  const handleChange = (newValue, actionMeta) => {
    console.log("change value", newValue, actionMeta);
    switch (actionMeta.action) {
      case "create-option":
        handleTopicManual(actionMeta.option.value);
        break;
      case "select-option":
        handleSelectTopic(newValue);
        // handleSelectOption(newValue);
        break;
      case "remove-value":
        handleSelectTopic(newValue);
        break;
      case "clear":
        handleSelectTopic(newValue);
        break;
      default:
        break;
    }
  };

  const handleTopicManual = async value => {
    console.log("create topic", value);
    try {
      const officeId = office.currentOffice.id;
      let resultCreate = await post(`api/v1/topics?office_id=${officeId}`, {
        name: value,
      });
      const topic = resultCreate.data.data;
      const updateOption = [...topicList, topic];
      setTopicList(updateOption);
      const updateValue = [...props.data, topic];
      const { handleUpdateData } = props;
      handleUpdateData(updateValue);
    } catch (error) {
      console.log("topic error", error);
    }
  };

  const handleSelectTopic = newValue => {
    console.log("new value", newValue);
    const topics = topicList.filter(item => {
      const optionItem = newValue.find(
        optionItem => optionItem.value === item.id
      );
      if (optionItem) {
        return true;
      } else {
        return false;
      }
    });
    const { handleUpdateData } = props;
    handleUpdateData(topics);
  };

  const handleQueryTopic = async value => {
    const officeId = office.currentOffice.id;

    setLoading(true);
    try {
      if (value === "") {
        const result = await get(
          `api/v1/topics?office_id=${officeId}&per_page=${perPage}&page=1`
        );
        const newTopicList = mergeArray(result.data.data, topicList);
        console.log("new topci list", topicList, newTopicList);
        setTopicList(newTopicList);
      } else {
        let result = await get(
          `api/v1/topics?office_id=${officeId}&query=${value}`
        );
        const newTopicList = mergeArray(result.data.data, topicList);
        console.log("new topci list", topicList, newTopicList);
        setTopicList(newTopicList);
      }
      setLoading(false);
    } catch (error) {
      console.log("load error", error);
      setLoading(false);
    }
  };

  const handleChangeInput = value => {
    handleQueryTopic(value);
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleChangeInput, 1000),
    []
  );

  const options = topicList
    ? topicList.map(item => ({
        value: item?.id,
        label: item?.attributes.name,
      }))
    : [];

  const value = props.data
    ? props.data.map(item => ({
        value: item.id.toString(),
        label: item.attributes.name,
      }))
    : [];

  return (
    <div className="mt-2">
      <CreatableSelect
        styles={customStyles}
        options={options}
        value={value}
        placeholder="Topics..."
        onChange={handleChange}
        // onInputChange={debouncedChangeHandler}
        isClearable={true}
        isLoading={loading}
        isMulti
      />
    </div>
  );
};

export default TaskTopics;
