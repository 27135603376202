import React from "react";
import Select from "react-select";
import { put } from "src/utils/axios";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import toast from "react-hot-toast";

const options = [
  {
    value: "strong_support",
    label: "Strong Support",
  },
  {
    value: "weak_support",
    label: "Weak Support",
  },
  {
    value: "strong_opposition",
    label: "Strong Opposition",
  },
  {
    value: "weak_opposition",
    label: "Weak Opposition",
  },
  {
    value: "no_opinion",
    label: "No Opinion",
  },
];

const ContactBillPosition = props => {
  const office = useSelector(state => state.office);
  const { thread_id } = useParams();
  const { valueList, source = "primary" } = props;

  const handleChangeOption = option => {
    const { handleUpdateValue } = props;
    let params;
    if (option) {
      params = option.value;
    } else {
      params = "";
    }
    if (source === "primary") {
      handleValue(params, option);
    } else if (source === "no-api") {
      handleUpdateValue(option);
    }
  };
  const handleValue = async param => {
    const officeId = office.currentOffice.id;
    const { handleUpdateValue, billId } = props;
    try {
      const result = await put(
        `api/v1/threads/${thread_id}/bills/${billId}?office_id=${officeId}`,
        {
          position: param,
        }
      );
      handleUpdateValue([result.data.data]);
    } catch (error) {
      console.log("error --- ", error);
      toast("Something broke, please retry your request…");
    }
  };

  const value = valueList
    ? options.find(item => item.value === valueList)
    : null;

  const customStyles = {
    option: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    input: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    singleValue: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
    placeholder: provided => ({
      ...provided,
      fontSize: "0.875rem",
    }),
  };

  return (
    <Select
      styles={customStyles}
      value={value}
      isDisabled={props.billId ? false : true}
      name="bills"
      options={options}
      onChange={handleChangeOption}
      className="basic-multi-select"
      classNamePrefix="select"
      placeholder="Bill position"
    />
  );
};

export default ContactBillPosition;
