import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "src/assets/logo.png";

const InvitedOfficeLogin = () => {
  return (
    <div className="h-screen w-screen flex flex-col justify-start items-center mt-32">
      <div className="w-1/3">
        <div className="mb-8 px-4 flex justify-center">
          <img src={logo} width="150" />
        </div>
        <div
          className="bg-green-100 border-t-4 border-green-500 rounded-b text-green-900 px-4 py-3 shadow-md"
          role="alert"
        >
          <div className="flex">
            <div className="py-1">
              <svg
                className="fill-current h-6 w-6 text-teal-500 mr-4"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
              >
                <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
              </svg>
            </div>
            <div>
              <p className="font-bold">Visit invited office page</p>
              <p className="text-sm">
                Please login or register to access this. Then come back to this
                link to request to join the office.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-12 text-center">
          <p className="ml-2">
            Go to login?{" "}
            <Link className="text-blue-700" to="/login">
              here
            </Link>
            .
          </p>
        </div>
      </div>
    </div>
  );
};

export default InvitedOfficeLogin;
