import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contacts: [],
  bills: [],
  issues: [],
  topics: [],
  users: [],
  templates: [],
  errorHandle: {
    errorFlag: false,
    errorField: "",
    errorIndex: 0,
  },
};

const slice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    setContacts(state, action) {
      state.contacts = action.payload;
    },
    setBills(state, action) {
      state.bills = action.payload;
    },
    setTopics(state, action) {
      state.topics = action.payload;
    },
    setIssues(state, action) {
      state.issues = action.payload;
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
    setTemplates(state, action) {
      state.templates = action.payload;
    },
    setErrorHandle(state, action) {
      state.errorHandle = action.payload;
    },
  },
});

export const actions = slice.actions;
export const reducer = slice.reducer;
