import { createSlice } from "@reduxjs/toolkit";
import { get } from "src/utils/axios";
import { actions as appActions } from "./app";

const initialState = {
  email_connected: undefined, // undefined | boolean
  email: "",
};

const slice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setEmailConnected(state, action) {
      state.email_connected = action.payload;
    },
    setEmail(state, action) {
      state.email = action.payload;
    },
  },
});

export const reducer = slice.reducer;

export const getEmailConnected = () => async dispatch => {
  const response = await get("api/v1/profile");
  if (response?.data?.data?.attributes?.email_connected) {
    dispatch(slice.actions.setEmailConnected(true));
    dispatch(
      slice.actions.setEmail(
        response.data.data.attributes.email_connected.email
      )
    );
    return true;
  }
  dispatch(slice.actions.setEmailConnected(false));
  return false;
};

export const init = () => async dispatch => {
  dispatch(appActions.setLoading(true));
  const res = await dispatch(getEmailConnected());
  if (res.status == 200) {
    dispatch(slice.actions.setEmailConnected(true));
  }

  dispatch(appActions.setLoading(false));
};
