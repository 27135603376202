import React, { useEffect } from "react";
import Nprogress from "nprogress";
import "./LoadingScreen.css";

const LoadingScreen = props => {
  const { isContent } = props;
  useEffect(() => {
    Nprogress.start();

    return () => {
      Nprogress.done();
    };
  }, []);

  return (
    <div
      className={`w-full z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center ${
        isContent ? "h-96" : "h-screen"
      }`}
    >
      <div className="loader ease-linear rounded-full border-4 border-t-4 border-gray-200 h-12 w-12 mb-4"></div>
      <h2 className="text-center text-gray-900 text-lg ">Loading...</h2>
    </div>
  );
};

export default LoadingScreen;
